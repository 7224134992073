import React, { useContext, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import { roundSurface } from '@utils'
import Outlier from '@modules/common/components/Outlier'
import { outlierIcons } from '@constants/outlierData'
import { truncateSurface } from '@common/utils/truncateSurface'

export const HarvestForm = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  readOnly = false,
  onPressLotsInput,
  crop: { areaUnit, company },
  openBackdropStandardDeviation,
  valueOutsideMean,
  isValueOutsideMeanHarvest,
  changeOutlierUnitType,
  changePayAndRequestOutlier,
  canShowOutlierHarvest,
  statusOutlier,
  isNewActivity,
}) => {
  const { t } = useContext(LanguageContext)
  const { unitTypes, unitTypesVolume } = useContext(CommonContext)
  const filterUnitTypes = unitTypes.filter(
    (unitType) => unitType.unitMeasureSystem === company?.unitMeasureSystem
  )
  const filterUnitTypesVolume = unitTypesVolume.filter(
    (unitTypeVolume) =>
      unitTypeVolume.unitMeasureSystem === company?.unitMeasureSystem
  )

  const canShowOutlierLevel =
    (isValueOutsideMeanHarvest && canShowOutlierHarvest) || valueOutsideMean

  useEffect(() => {
    if (values?.unitVolume?.key) {
      const value = filterUnitTypes.find(
        (unitType) => unitType.key.split('/')[0] === values?.unitVolume?.key
      )
      if (values?.unitType?.key !== value?.key) {
        setFieldValue('unitType', value)
        changeOutlierUnitType(value)
      }
    }
  }, [values?.unitVolume])

  useEffect(() => {
    if (values?.surface && values?.volume && values?.volume !== 'undefined') {
      const value = values?.volume / values?.surface
      if (
        !readOnly &&
        roundSurface(Number(values?.pay)) !== roundSurface(value)
      ) {
        setFieldValue('pay', value)
      }

      if (value) {
        changePayAndRequestOutlier(value)
      }
    }
  }, [values?.surface, values?.volume, values?.unitType, isNewActivity])

  return (
    <View>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_1.LABEL')}`}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputText
        id='surface'
        containerStyle={styles.containerInput}
        value={values.surface && `${truncateSurface(values.surface)}`}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_2.PLACEHOLDER',
          { areaUnit }
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_2.LABEL', {
          areaUnit,
        })}`}
        editable={false}
        disabled
        onBlur={() => setFieldTouched('surface')}
        touched={touched.surface}
        error={errors.surface}
      />

      <InputDate
        id='dateHarvest'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('dateHarvest', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('dateHarvest'))}
        value={values.dateHarvest}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_3.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_3.LABEL')}`}
        disablePast={false}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.dateHarvest}
        error={errors.dateHarvest?.value}
      />

      <InputSelectDefault
        id='unitVolume'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('unitVolume', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('unitVolume'))}
        value={values.unitVolume}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_VOLUME_UNIT.PLACEHOLDER'
        )}`}
        label={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_VOLUME_UNIT.LABEL'
        )}`}
        options={filterUnitTypesVolume}
        disabled={Boolean(readOnly)}
        touched={touched.unitVolume}
        error={errors.unitVolume?.value}
      />

      <InputText
        id='volume'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('volume', value)
        }}
        value={values.volume}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_5.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_5.LABEL')}`}
        keyboardType='decimal-pad'
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('volume')}
        touched={touched.volume}
        error={errors.volume}
      />

      <InputText
        id='pay'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('pay', value)
        }}
        value={
          values.pay && values?.unitType?.key
            ? `${roundSurface(values.pay)} ${values?.unitType?.key}`
            : ''
        }
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_6.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.HARVEST_FORM.FIELD_6.LABEL')}`}
        keyboardType='number-pad'
        editable={false}
        disabled
        onBlur={() => setFieldTouched('pay')}
        touched={touched.pay}
        error={errors.pay}
      />

      {canShowOutlierLevel && (
        <Outlier
          title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
          icon={outlierIcons.InfoFill}
          description={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_DESCRIPTION}
          type={statusOutlier}
          style={styles.outlier}
          onPress={openBackdropStandardDeviation}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  outlier: {
    marginTop: -15,
    marginBottom: 15,
  },
})

HarvestForm.propTypes = {
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onPressLotsInput: PropTypes.func.isRequired,
  crop: PropTypes.object,
  openBackdropStandardDeviation: PropTypes.func,
  canShowOutlier: PropTypes.bool,
  isValueOutsideMean: PropTypes.bool,
  isNewActivity: PropTypes.bool,
}
