import Constants from 'expo-constants'
import { DrawerContentScrollView } from '@react-navigation/drawer'
import { StyleSheet, View, Platform, Image, Pressable } from 'react-native'
import { Avatar, Subheading, Paragraph } from 'react-native-paper'

import { grayLight, white, grayInput, black, alertText } from '@styles/palette'
import { useContext } from 'react'
import avatarDefault from '../../../../../assets/images/avatar_default.png'
import DrawerItem from '../DrawerItem/DrawerItem'
import { CommonIcon } from '../../../common/components'
import { useSelector } from 'react-redux'
import { LanguageContext } from '../../../../contextState/language'
import useAuthCognito from '../../../common/hooks/useAuthCognito'
import {
  canSeeCompany,
  canSeeCrops,
  canSeeExporterCircuit,
  canSeeFarms,
  canSeeLicense,
  canSeeProductiveUnit,
  canSeeQR,
  canSeeQuickSightTraders,
} from '../../../../utils'
import { companySelectedHasCountry } from '@utils/countries'
import userTypes from '../../../../constants/userTypes'
import RouterNames from '../../../../constants/routerNames'
import Animated from 'react-native-reanimated'
import { gray200 } from '@common/styles'
import {
  userPermissions,
  validateRoleInUserConfigService,
} from '@utils/permissions'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { encrypt } from '@utils/crypto'
import { RoleEnum } from '@common/enum'
import { useAuth } from '@common/hooks'

export const CustomDrawer = ({
  navigation,
  route,
  currentRouteName,
  setDrawerOpen,
  isCompleteMode,
  ...props
}) => {
  const {
    user,
    config,
    isCompanyUcropit,
    isVerifierUcropit,
    isPendingAuthorization,
  } = useAuth()

  const companyData = config?.companyAdmin ?? config.companySelected
  const rolSelectedDescription = config?.roleSelected?.keyLabel

  const { isConnected } = useSelector((state) => state.connectionReducer)

  const { t } = useContext(LanguageContext)
  const { redirectQuickSight } = useAuthCognito()

  const isUcropit = isCompanyUcropit || isVerifierUcropit

  function handleEdit() {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: RouterNames.PROFILE,
        },
      ],
    })
  }

  function goSettings() {
    closeDrawerOnNavigate()

    navigation.reset({
      index: 0,
      routes: [
        {
          name: RouterNames.SETTINGS,
        },
      ],
    })
  }

  function trimStringPassLength(string, length) {
    if (length > 20) {
      return `${string.substring(0, 20)}...`
    }

    return string
  }

  const navigateCompanyListIsAdviser = () => {
    closeDrawerOnNavigate()

    navigation.reset({
      index: 0,
      routes: [
        {
          name: RouterNames.COMPANY,
          params: {
            initialRouteName: RouterNames.COMPANY_LIST_UCROPIT,
          },
        },
      ],
    })
  }

  const navigateCompanyListOrAdd = () => {
    closeDrawerOnNavigate()

    const params = !config.companySelected
      ? {
          initialRouteName: RouterNames.COMPANY_ADD,
        }
      : {}

    navigation.reset({
      index: 0,
      routes: [
        {
          name: RouterNames.COMPANY,
          params,
        },
      ],
    })
  }

  const { roleSelected, companySelected } = config

  const hasCompany = Boolean(companySelected)

  const showFarm = canSeeFarms({ roleSelected })

  const showCrops = canSeeCrops({ roleSelected })

  const showCompany = canSeeCompany({ roleSelected })

  const showExporterCircuit = canSeeExporterCircuit({ roleSelected })

  const showProductiveUnit = canSeeProductiveUnit({ roleSelected })

  const showQR = canSeeQR({ roleSelected })

  const disableFarm =
    isPendingAuthorization || !hasCompany || !showFarm || !isConnected

  const isAdviser =
    config?.roleSelected?.value === RoleEnum.ADVISER ||
    config?.roleSelected?.value === RoleEnum.TECHNICAL_ADVISER

  const showQuickSight =
    isCompleteMode &&
    Platform.OS === 'web' &&
    config?.roleSelected?.value === userTypes.PRODUCER.key &&
    config?.isAdmin

  const showQuickSightTraders = canSeeQuickSightTraders({
    isCompleteMode,
    companyTypes: config?.companySelected?.types,
    platform: Platform.OS,
  })

  const dataIngestUrl =
    Constants.expoConfig?.extra?.DATA_HUB_URL ||
    'https://data-ingest-middle-ui-qa.ucrop.it'

  const handleRedirectDataHub = async () => {
    const token = await AsyncStorage.getItem('USER_TOKEN')
    const {
      config: {
        companySelected: { identifier, _id: companyId },
        roleSelected: { value },
      },
    } = user
    const param = encrypt(
      JSON.stringify({
        identifier: identifier,
        token: token,
        role: value,
        companyId,
      })
    )
    const url = `${dataIngestUrl}/activities-import?data=${String(param)}`

    if (url) {
      window.open(url, '_blank')
    }
  }

  const routes = {
    NotificationList: {
      icon: 'NOTIFICATIONS',
      disabled: !isConnected,
      show: showFarm || !hasCompany,
      name: t('VIEWS.NOTIFICATIONS.NAVIGATION.TITLE'),
      route: RouterNames.NOTIFICATION,
      left: () => (
        <View style={styles.notificationIconContainer}>
          <CommonIcon
            name='NOTIFICATIONS'
            size={24}
            style={!isCompleteMode ? styles.leftIconMobile : {}}
          />

          <View style={styles.notificationStatusContainer}>
            <CommonIcon name='CIRCLE-FILLED' size={8} color={alertText} />
          </View>
        </View>
      ),
    },
    FarmMap: {
      icon: 'FARMS',
      disabled: disableFarm,
      show: showFarm || !hasCompany,
      name: t('NAVIGATION.CUSTOM_TAB_BAR.TEXT_3'),
      route: RouterNames.FARMS,
      left: () => (
        <View style={styles.iconContainer}>
          <CommonIcon
            name='FARMS'
            size={24}
            style={!isCompleteMode ? styles.leftIconMobile : {}}
          />
        </View>
      ),
    },
    CropList: {
      icon: 'CROPS',
      disabled: false,
      show: showCrops,
      name: t('NAVIGATION.CUSTOM_TAB_BAR.TEXT_4'),
      route: RouterNames.CROPS,
      left: () => (
        <CommonIcon
          name={routes.CropList.icon}
          size={24}
          style={!isCompleteMode ? styles.leftIconMobile : {}}
        />
      ),
    },
    LicenseList: {
      icon: 'LICENSES',
      disabled: isPendingAuthorization || !isConnected,
      show:
        canSeeLicense({ roleSelected }) &&
        !companySelectedHasCountry(companySelected, 'PRY'),
      name: t('NAVIGATION.CUSTOM_TAB_BAR.LICENCES'),
      route: RouterNames.LICENSES,
      left: () => (
        <CommonIcon
          name={routes.LicenseList.icon}
          size={24}
          style={!isCompleteMode ? styles.leftIconMobile : {}}
        />
      ),
    },
    bulkImport: {
      icon: 'BULK_IMPORT',
      disabled: false,
      show: isAdviser,
      name: t('NAVIGATION.CUSTOM_TAB_BAR.TEXT_5'),
      left: () => (
        <View style={styles.notificationIconContainer}>
          <CommonIcon
            name='BULK_IMPORT'
            size={24}
            style={!isCompleteMode ? styles.leftIconMobile : {}}
          />
        </View>
      ),
      onPress: () => {
        handleRedirectDataHub()
      },
    },
    Company: {
      icon: isCompleteMode ? 'COMPANY' : 'COMPANY-SWITCH-BUILDING',
      disabled: !isConnected,
      show: showCompany,
      name: t('NAVIGATION.CUSTOM_DRAWER.TEXT_6'),
      route: RouterNames.COMPANY,
      onPress: () => {
        if (!isCompleteMode) {
          if (config.companyAdmin) {
            navigateCompanyListIsAdviser()
          } else {
            navigateCompanyListOrAdd()
          }
        }
      },
      left: () => (
        <CommonIcon
          name={routes.Company.icon}
          size={24}
          style={!isCompleteMode ? styles.leftIconMobile : {}}
        />
      ),
    },
    CompanyList: {
      show: showCompany,
      renderItem: () =>
        isCompleteMode && (
          <DrawerItem
            onPress={navigateCompanyListOrAdd}
            disable={!isConnected}
            title={
              config.companySelected
                ? trimStringPassLength(
                    companyData?.name,
                    companyData?.name.length
                  )
                : t('NAVIGATION.CUSTOM_DRAWER.ADD_COMPANY')
            }
            description={
              config.companySelected
                ? `${t('NAVIGATION.CUSTOM_DRAWER.TAX_ID')} ${
                    companyData?.identifier
                  }`
                : ''
            }
            right={() => (
              <CommonIcon
                name={'COMPANY-SWITCH'}
                size={24}
                color={black}
                style={styles.iconRightItem}
              />
            )}
            titleStyle={[styles.titleItem, { marginLeft: 0 }]}
            itemStyle={styles.childItem}
          />
        ),
    },
    CompanyListUcropit: {
      show: showCompany,
      renderItem: () =>
        isUcropit &&
        isCompleteMode && (
          <View>
            {config?.companyAdmin &&
            config?.companyAdmin?._id !== config?.companySelected?._id ? (
              <DrawerItem
                label={t('NAVIGATION.CUSTOM_DRAWER.ADVISED_COMPANY')}
                onPress={navigateCompanyListIsAdviser}
                disable={!isConnected}
                title={trimStringPassLength(
                  config.companySelected?.name,
                  config.companySelected?.name.length
                )}
                description={`${t('NAVIGATION.CUSTOM_DRAWER.TAX_ID')} ${
                  config.companySelected?.identifier
                }`}
                right={() => (
                  <CommonIcon
                    name={'COMPANY-SWITCH'}
                    size={24}
                    color={black}
                    style={styles.iconRightItem}
                  />
                )}
                titleStyle={[styles.titleItem, { marginLeft: 0 }]}
                itemStyle={[styles.childItem]}
              />
            ) : (
              <DrawerItem
                title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_16')}
                onPress={navigateCompanyListIsAdviser}
                disable={!isConnected}
                right={() => (
                  <CommonIcon
                    name={'COMPANY-SWITCH'}
                    size={24}
                    color={black}
                    style={styles.iconRightItem}
                  />
                )}
                titleStyle={[styles.titleItem, { marginLeft: 0 }]}
                itemStyle={[styles.childItem, { marginTop: 13 }]}
              />
            )}
          </View>
        ),
    },
    CompanyDetail: {
      show: showCompany,
      renderItem: () =>
        isCompleteMode ? (
          <DrawerItem
            onPress={() => {
              closeDrawerOnNavigate()

              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: RouterNames.COMPANY,
                    params: {
                      initialRouteName: RouterNames.COMPANY_DETAIL,
                    },
                  },
                ],
              })
            }}
            disable={
              !isConnected || isPendingAuthorization || !config.companySelected
            }
            title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_13')}
            titleStyle={[styles.titleChildItem, { marginLeft: 0 }]}
            itemStyle={[styles.childItem]}
          />
        ) : null,
    },
    QuickSight: {
      show: showQuickSight,
      renderItem: () => (
        <View>
          <DrawerItem
            onPress={redirectQuickSight}
            title={t('NAVIGATION.CUSTOM_DRAWER.PRODUCTION_BOARD')}
            titleStyle={[styles.titleChildItem, { marginLeft: 0 }]}
            itemStyle={[styles.childItem]}
          />
        </View>
      ),
    },
    SyncOffline: {
      renderItem: () =>
        Platform.OS !== 'web' && (
          <View>
            <DrawerItem
              onPress={() => {
                closeDrawerOnNavigate()

                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      name: RouterNames.SETUP,
                    },
                  ],
                })
              }}
              disable={isPendingAuthorization || !config.companySelected}
              title={t('NAVIGATION.CUSTOM_DRAWER.TEXT_14')}
              titleStyle={[styles.titleChildItem, { marginLeft: 0 }]}
              itemStyle={[styles.childItem]}
            />
          </View>
        ),
    },
    Integrations: {
      show: validateRoleInUserConfigService(
        userPermissions.CREATE_PLUGIN,
        roleSelected
      ),
      renderItem: () => (
        <DrawerItem
          onPress={() => {
            closeDrawerOnNavigate()

            navigation.reset({
              index: 0,
              routes: [
                {
                  name: RouterNames.COMPANY,
                  params: {
                    initialRouteName: RouterNames.COMPANY_SERVICE_INTEGRATIONS,
                    id: config.companySelected?._id,
                  },
                },
              ],
            })
          }}
          disable={
            !isConnected || isPendingAuthorization || !config.companySelected
          }
          title={t('NAVIGATION.CUSTOM_DRAWER.INTEGRATIONS')}
          titleStyle={[styles.titleChildItem, { marginLeft: 0 }]}
          itemStyle={[styles.childItem]}
        />
      ),
    },
    Settings: {
      icon: 'SETTINGS',
      disabled: !isConnected,
      show: true,
      name: t('NAVIGATION.CUSTOM_DRAWER.SETTINGS'),
      route: RouterNames.SETTINGS,
    },
    Traders: {
      icon: 'TRADERS',
      disabled: !isConnected,
      show: showExporterCircuit,
      name: t('NAVIGATION.CUSTOM_DRAWER.TRADERS'),
      route: RouterNames.TRADERS,
      left: () => (
        <View style={styles.notificationIconContainer}>
          <CommonIcon
            name='TRADERS'
            size={24}
            style={!isCompleteMode ? styles.leftIconMobile : {}}
          />
        </View>
      ),
    },
    QR: {
      icon: 'TRACEABILITY',
      disabled: !isConnected,
      show: showQR,
      name: t('NAVIGATION.CUSTOM_DRAWER.QR'),
      route: RouterNames.QR,
      left: () => (
        <View style={styles.notificationIconContainer}>
          <CommonIcon
            name='TRACEABILITY'
            size={24}
            style={!isCompleteMode ? styles.leftIconMobile : {}}
          />
        </View>
      ),
    },
    BulkUpload: {
      icon: 'FIELDS',
      disabled: !isConnected,
      show: showProductiveUnit,
      name: t('NAVIGATION.CUSTOM_DRAWER.BULK_UPLOAD'),
      left: () => (
        <View style={styles.iconContainer}>
          <CommonIcon
            name='CERTIFICATION'
            size={24}
            style={!isCompleteMode ? styles.leftIconMobile : {}}
          />
        </View>
      ),
    },
    ProductiveUnits: {
      disabled: !isConnected,
      show: showProductiveUnit,
      name: t('NAVIGATION.CUSTOM_DRAWER.PRODUCTIVE_UNITS'),
      renderItem: () => (
        <View>
          <DrawerItem
            onPress={() => {
              closeDrawerOnNavigate()

              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: RouterNames.POLYGONS_TRADER,
                    params: {
                      initialRouteName: RouterNames.POLYGONS_TRADER,
                    },
                  },
                ],
              })
            }}
            title={t('NAVIGATION.CUSTOM_DRAWER.PRODUCTIVE_UNITS')}
            titleStyle={[styles.titleChildItem, { marginLeft: 0 }]}
            itemStyle={[styles.childItem]}
          />
        </View>
      ),
    },
    QuickSightTraders: {
      show: showQuickSightTraders,
      disabled: !isConnected,
      renderItem: () => (
        <View>
          <DrawerItem
            onPress={() => {
              closeDrawerOnNavigate()

              navigation?.navigate(RouterNames.QUICK_SIGHT_TRADER)
            }}
            title={t('NAVIGATION.CUSTOM_DRAWER.PRODUCTION_BOARD')}
            titleStyle={[styles.titleChildItem, { marginLeft: 0 }]}
            itemStyle={[styles.childItem]}
          />
        </View>
      ),
    },
  }

  const routesRender = [
    routes.NotificationList,
    routes.FarmMap,
    routes.CropList,
    routes.LicenseList,
    // routes traders
    routes.Traders,
    routes.BulkUpload,
    routes.ProductiveUnits,
    routes.QuickSightTraders,
    routes.QR,
    routes.bulkImport,
    // routes traders
    routes.Company,
    routes.CompanyList,
    routes.CompanyListUcropit,
    routes.CompanyDetail,
    routes.QuickSight,
    routes.SyncOffline,
    routes.Integrations,
  ]

  const goToNavigate = (route) => {
    closeDrawerOnNavigate()

    navigation.reset({
      index: 0,
      routes: [{ name: route }],
    })
  }

  const press = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setDrawerOpen()
  }

  const closeDrawerOnNavigate = () => {
    if (Platform.OS !== 'web' && isCompleteMode) {
      setDrawerOpen()
    }
  }

  const itemContainerHorizontalPadding = {
    marginHorizontal: isCompleteMode ? 16 : 10,
    paddingHorizontal: isCompleteMode ? 16 : 0,
  }

  return (
    <Animated.View style={styles.containerView}>
      <DrawerContentScrollView {...props}>
        <View style={{ flex: 1 }}>
          <View style={styles.container}>
            {user.avatar ? (
              <Avatar.Image size={40} source={{ uri: user.avatarPath }} />
            ) : (
              <Image source={avatarDefault} style={styles.image} />
            )}

            {isCompleteMode && (
              <Pressable
                onPress={(e) => {
                  e.stopPropagation()
                  handleEdit()
                }}
              >
                <View
                  style={{
                    flex: 1,
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Subheading style={styles.subheading}>
                    {user.firstName} {user.lastName}
                  </Subheading>
                  <CommonIcon
                    name={'CHEVRON-RIGHT'}
                    size={20}
                    style={{ marginTop: 20 }}
                  />
                </View>

                {Boolean(rolSelectedDescription) && (
                  <Paragraph style={styles.rol}>
                    {rolSelectedDescription}
                  </Paragraph>
                )}

                <Paragraph style={styles.email}>{user.email}</Paragraph>
              </Pressable>
            )}
          </View>

          <View>
            {!isCompleteMode && (
              <DrawerItem
                onPress={handleEdit}
                disable={!isConnected}
                title={routes.Settings.name}
                left={() => (
                  <CommonIcon
                    name={'PROFILE'}
                    size={24}
                    style={!isCompleteMode ? styles.leftIconMobile : {}}
                  />
                )}
                active={RouterNames.PROFILE === currentRouteName}
                containerItemStyle={[
                  styles.containerItem,
                  itemContainerHorizontalPadding,
                ]}
                onlyIcon={!isCompleteMode}
              />
            )}
            {routesRender
              .filter((route) => route.show)
              .map((route, index) => {
                if (route.renderItem) {
                  return (
                    <View key={`item-menu-${index}`}>{route.renderItem()}</View>
                  )
                }
                return (
                  <DrawerItem
                    key={`item-menu-${index}`}
                    onPress={route.onPress || (() => goToNavigate(route.route))}
                    disable={route.disabled}
                    title={route.name}
                    left={route.left}
                    active={route.route === currentRouteName}
                    titleStyle={styles.titleItem}
                    containerItemStyle={[
                      styles.containerItem,
                      itemContainerHorizontalPadding,
                    ]}
                    onlyIcon={!isCompleteMode}
                  />
                )
              })}
          </View>
        </View>
      </DrawerContentScrollView>

      <View
        style={{
          justifyContent: 'flex-end',
          paddingBottom: 24,
        }}
      >
        <DrawerItem
          onPress={goSettings}
          disable={!isConnected}
          title={routes.Settings.name}
          left={() => (
            <CommonIcon
              name={routes.Settings.icon}
              size={24}
              style={!isCompleteMode ? styles.leftIconMobile : {}}
            />
          )}
          active={routes.Settings.route === currentRouteName}
          titleStyle={styles.titleItem}
          containerItemStyle={[
            styles.containerItem,
            itemContainerHorizontalPadding,
            { marginBottom: 16 },
          ]}
          onlyIcon={!isCompleteMode}
        />

        {Platform.OS === 'web' ? (
          <View
            style={{
              ...styles.toggleDrawerButton,
              transform: [{ translateX: isCompleteMode ? 237 : 12 }],
              width: isCompleteMode ? 58 : 60,
            }}
          >
            <Pressable onPress={press}>
              <CommonIcon
                name={
                  !isCompleteMode
                    ? 'SIDEBAR-LEFT-EXPAND'
                    : 'SIDEBAR-LEFT-COLLAPSE'
                }
                size={24}
                style={[
                  styles.iconItem,
                  {
                    transform: [{ translateX: -5 }],
                  },
                ]}
              />
            </Pressable>
          </View>
        ) : null}
      </View>
    </Animated.View>
  )
}

const styles = StyleSheet.create({
  containerView: {
    flex: 1,
  },
  icon: {
    marginHorizontal: 0,
    height: 22,
    width: 22,
  },
  paragraph: {
    color: grayInput,
    marginBottom: 8,
  },
  subheading: {
    marginTop: 16,
    fontWeight: '700',
    marginBottom: 0,
    textTransform: 'capitalize',
    color: black,
  },
  email: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 20,
    color: black,
  },
  company: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheadingCompany: {
    fontWeight: '500',
    fontSize: 14,
    marginTop: 4,
    marginBottom: 0,
  },
  button: {
    width: '60%',
  },
  container: {
    paddingHorizontal: 16,
    paddingBottom: 32,
    paddingTop: 8,
  },
  label: {
    color: white,
  },
  avatar: {
    backgroundColor: grayLight,
    color: '#B8B8B8',
  },
  containerItem: {
    cursor: 'pointer',
  },
  leftIconMobile: {
    marginLeft: 15,
    width: '100%',
  },
  cursorAuto: {
    cursor: 'auto',
  },
  titleItem: {
    fontSize: 16,
    fontWeight: '400',
    color: black,
    justifyContent: 'flex-start',
    marginTop: -3,
    marginLeft: -5,
  },
  titleChildItem: {
    fontSize: 12,
    fontWeight: '400',
    color: black,
    justifyContent: 'flex-start',
    marginTop: -3,
  },
  iconItem: {
    color: black,
  },
  iconRightItem: {
    marginTop: -8,
  },
  childItem: {
    marginLeft: 64,
    cursor: 'pointer',
    borderLeftWidth: 1,
    borderLeftColor: gray200,
  },
  image: {
    width: 40,
    height: 40,
  },
  rol: {
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: 20,
    color: black,
  },
  notificationIconContainer: {
    position: 'relative',
  },
  notificationStatusContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 10,
    height: 10,
    backgroundColor: white,
    position: 'absolute',
    top: 2,
    right: 2,
    borderRadius: 50,
  },
  notificationStatus: {
    fontSize: 8,
    color: alertText,
  },
  toggleDrawerButton: {
    // backgroundColor: white,
    backgroundColor: gray200,
    width: 58,
    height: 48,
    zIndex: 1,
    // borderRadius: 6,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    padding: 4,

    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
    // top: 16,
    // backgroundColor: white,
    // width: 32,
    // zIndex: 1,
    // height: 32,
    // borderRadius: 6,
    // padding: 4,
    // position: 'absolute',
    // top: 16,
  },
})
