export default {
  TEXT_1: '¿Deseas salir de UCROP.IT?',
  TEXT_2: 'Realizando esta acción eliminaras la cola de sincronización',
  TEXT_3: 'Sin empresa asignada ',
  TEXT_4: 'Alta de Empresa',
  TEXT_5: 'EDITAR PERFIL',
  TEXT_6: 'Empresa',
  TEXT_7: 'Integraciones',
  TEXT_8: 'Ajustes',
  TEXT_9: 'Cerrar sesión',
  TEXT_10: 'Versión %{version} Build %{build}',
  TEXT_11: 'Perfil',
  TEXT_12: 'Idioma',
  TEXT_13: 'Datos de la empresa',
  TEXT_14: 'Sincronizar offline',
  TEXT_15: 'Conectar aplicaciones',
  TEXT_16: 'Asesorar empresa',
  SETTINGS: 'Configuración',
  PRODUCTION_BOARD: 'Tablero de producción',
  ADD_COMPANY: 'Agregar empresa',
  YOUR_COMPANY: 'TU EMPRESA',
  ADVISED_COMPANY: 'EMPRESA ASESORADA',
  INTEGRATIONS: 'Integraciones',
  TAX_ID: 'CUIT',
  TRADERS: 'Comercios',
  QR: 'Trazabilidad',
  POLYGONS: 'Polígonos',
  INFORMATION: 'Información',
  BULK_UPLOAD: 'Uso de la tierra',
  PRODUCTIVE_UNITS: 'Unidades productivas',
}
