import { SafeAreaView, StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { WebViewRender } from '@modules/webView/components'
import { makeRequest } from '@common/services'
import { getQuickSightUrl } from '@services/amplify'
import Constants from 'expo-constants'

const MS_AUTH_URL = Constants.expoConfig?.extra?.MS_AUTH_URL

const QuickSightTrader = () => {
  const [quickSightUrl, setQuickSightUrl] = useState<string>('')

  useEffect(() => {
    fetchInit()
  }, [])

  const fetchInit = async () => {
    try {
      const url = await getQuickSightUrl()

      const response = await makeRequest({
        method: 'GET',
        url,
        version: 'v2',
        prefix: 'api',
        apiUrl: MS_AUTH_URL,
      })

      setQuickSightUrl(response.data?.quickSightUrl)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      {!Boolean(quickSightUrl) ? (
        <LoaderCenterContainer />
      ) : (
        <WebViewRender url={quickSightUrl} />
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})

export default QuickSightTrader
