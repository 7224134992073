import AsyncStorage from '@react-native-async-storage/async-storage'
import BsonOID from 'bson-objectid'
import { round } from 'lodash'

import { userPermissions } from '@utils/permissions'
import COMPANY_TYPE from '@constants/companyTypes'

export const canSeeFarms = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_FARM
    )
  )

export const canSeeCrops = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_CROP
    )
  )

export const canSeeCompany = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_COMPANY
    )
  )

export const canSeeLicense = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_LICENSE
    )
  )

export const canApproveCompany = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.APPLY_VETTED_COMPANY
    )
  )

export const canSeeApprovedCompany = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_VETTED_COMPANY
    )
  )

export const canSeeExporterCircuit = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_EXPORTER_CIRCUIT
    )
  )

export const canSeeProductiveUnit = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_PRODUCTIVE_UNIT
    )
  )

export const canSeeQR = ({ roleSelected }) =>
  Boolean(
    roleSelected?.permissions?.find(
      (permission) => permission === userPermissions.VIEW_QR
    )
  )

export const canSeeQuickSightTraders = ({
  companyTypes = [],
  isCompleteMode,
  platform,
}) => {
  const isTraderCompany = companyTypes?.some(
    (type) => type?.name === COMPANY_TYPE.TRADERS.key
  )

  return isCompleteMode && isTraderCompany && platform === 'web'
}

export const ObjectId = () => {
  return BsonOID().toString()
}

export const filterSubActivityTypesByType = (
  subTypeActivities,
  typeActivity
) => {
  return subTypeActivities.filter(
    (subType) => subType.activityType === typeActivity
  )
}

export const setLocalStorage = async (key, value) =>
  await AsyncStorage.setItem(key, JSON.stringify(value))

export const getLocalStorage = async (key, defaultValue = null) => {
  const response = await AsyncStorage.getItem(key)
  return response && response !== 'undefined'
    ? JSON.parse(response)
    : defaultValue
}

export const isEmptyValue = (value) => {
  return value === null || value === undefined || value === ''
}

export const findActivitiesInCrop = (crop, activityId) => {
  const activities = [
    ...crop.pending,
    ...crop.toMake,
    ...crop.done,
    ...crop.finished,
  ]
  return activities.find((activity) => activity._id === activityId)
}

export const getObjectId = (element) => element?._id ?? element

export const getItem = (data, index) => {
  return {
    index,
    ...data[index],
  }
}

/**
 * Returns the sum with respect to the key
 *
 * @param {Array} list
 * @param {string} key
 * @returns
 */
export const sumByProp = (list, key) =>
  list.reduce((current, item) => current + item[key], 0)

/**
 * Returns num round with 2 decimal
 *
 * @param {number} surface
 * @returns
 *
 */
export const roundSurface = (surface) => round(Number(surface), 2).toString()

export const cipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0))
  const byteHex = (n) => ('0' + Number(n).toString(16)).substr(-2)
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code)

  return (text) =>
    text.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('')
}

export const decipher = (salt) => {
  const textToChars = (text) => text.split('').map((c) => c.charCodeAt(0))
  const applySaltToChar = (code) =>
    textToChars(salt).reduce((a, b) => a ^ b, code)
  return (encoded) =>
    encoded
      .match(/.{1,2}/g)
      .map((hex) => parseInt(hex, 16))
      .map(applySaltToChar)
      .map((charCode) => String.fromCharCode(charCode))
      .join('')
}
