import React, { useContext } from 'react'
import { StyleSheet, View } from 'react-native'
import { PropTypes } from 'prop-types'

import { CommonIcon, InputSelectDefault } from '@modules/common/components'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import InputText from '@components/inputs/InputText'
import InputDate from '@components/inputs/InputDate'
import Outlier from '@modules/common/components/Outlier'
import { outlierIcons } from '@constants/outlierData'
import { EPayUnitType } from '@modules/common/enum'

export const MonitoringForm = ({
  formik: { setFieldValue, values, setFieldTouched, touched, errors },
  readOnly = false,
  onPressLotsInput,
  crop: { company },
  openBackdropStandardDeviation,
  changeOutlierUnitType,
  changePayAndRequestOutlier,
  isValueOutsideMeanHarvest,
  canShowOutlierHarvest,
  statusOutlier,
  valueOutsideMean,
}) => {
  const { t } = useContext(LanguageContext)
  const { unitTypes } = useContext(CommonContext)

  const filterUnitTypes = unitTypes.filter(
    (unitType) =>
      unitType.unitMeasureSystem === company?.unitMeasureSystem &&
      Object.values(EPayUnitType).includes(unitType.key)
  )

  const canShowOutlierLevel =
    (isValueOutsideMeanHarvest && canShowOutlierHarvest) || valueOutsideMean

  return (
    <View>
      <InputText
        id='lots'
        containerStyle={styles.containerInput}
        rightIcon={
          <CommonIcon
            name={'CHEVRON-RIGHT'}
            size={20}
            onPress={onPressLotsInput}
          />
        }
        value={values.lots.label}
        onPress={() => {
          onPressLotsInput()
          setFieldTouched('lots')
        }}
        editable={false}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')}`}
        touched={touched.lots}
        error={errors.lots?.value}
      />

      <InputDate
        id='dateObservation'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('dateObservation', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('dateObservation'))}
        value={values.dateObservation}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_2.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_2.LABEL')}`}
        disablePast={false}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.dateObservation}
        error={errors.dateObservation?.value}
      />

      <InputSelectDefault
        id='unitType'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('unitType', value)
          changeOutlierUnitType(value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('unitType'))}
        value={values.unitType}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_3.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_3.LABEL')}`}
        options={filterUnitTypes}
        disabled={Boolean(readOnly)}
        touched={touched.unitType}
        error={errors.unitType?.value}
      />

      <InputText
        id='pay'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('pay', value)
          changePayAndRequestOutlier(value)
        }}
        value={values.pay}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_4.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_4.LABEL')}`}
        keyboardType='number-pad'
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('pay')}
        touched={touched.pay}
        error={errors.pay}
      />

      {canShowOutlierLevel && (
        <Outlier
          title={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_TITLE}
          icon={outlierIcons.InfoFill}
          description={t('VIEWS').CROP_CREATE_CONFIRM.OUTLIER_DESCRIPTION}
          type={statusOutlier}
          style={styles.outlier}
          onPress={openBackdropStandardDeviation}
        />
      )}

      <InputText
        id='observation'
        containerStyle={styles.containerInput}
        onChangeText={(value) => setFieldValue('observation', value)}
        value={values.observation}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_5.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_5.LABEL')}`}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        onBlur={() => setFieldTouched('observation')}
        touched={touched.observation}
        error={errors.observation}
        multiline={true}
      />

      <InputDate
        id='dateEstimatedHarvest'
        containerStyle={styles.containerInput}
        onChangeText={(value) => {
          setFieldValue('dateEstimatedHarvest', value)
        }}
        onBlur={() => setTimeout(() => setFieldTouched('dateEstimatedHarvest'))}
        value={values.dateEstimatedHarvest}
        placeholder={`${t(
          'VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_6.PLACEHOLDER'
        )}`}
        label={`${t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_6.LABEL')}`}
        disablePast={false}
        editable={Boolean(!readOnly)}
        disabled={Boolean(readOnly)}
        touched={touched.dateEstimatedHarvest}
        error={errors.dateEstimatedHarvest?.value}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  containerInput: {
    marginVertical: 1,
  },
  outlier: {
    marginTop: -15,
    marginBottom: 15,
  },
})

MonitoringForm.propTypes = {
  formik: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  onPressLotsInput: PropTypes.func.isRequired,
  crop: PropTypes.object,
  openBackdropStandardDeviation: PropTypes.func,
  canShowOutlier: PropTypes.bool,
  isValueOutsideMean: PropTypes.bool,
  isNewActivity: PropTypes.bool,
  changeOutlierUnitType: PropTypes.func,
  changePayAndRequestOutlier: PropTypes.func,
  isValueOutsideMeanHarvest: PropTypes.bool,
  canShowOutlierHarvest: PropTypes.bool,
  statusOutlier: PropTypes.string,
}
