export enum RoleEnum {
  ADVISER = 'ADVISER',
  VERIFIER = 'VERIFIER',
  PRODUCER_ADVISER_ENCOURAGED = 'PRODUCER_ADVISER_ENCOURAGED',
  PRODUCER = 'PRODUCER',
  PROVIDER = 'PROVIDER',
  KAM = 'KAM',
  MARKETER = 'MARKETER',
  CONTACT_REPRESENTATIVE = 'CONTACT_REPRESENTATIVE',
  TECHNICAL_ADVISER = 'TECHNICAL_ADVISER',
  TRADER = 'TRADER',
  QR_ADMIN = 'QR_ADMIN',
  QR_VIEW = 'QR_VIEW',
  PRODUCTIVE_UNIT = 'PRODUCTIVE_UNIT',
  EXPORTER_CIRCUIT = 'EXPORTER_CIRCUIT',
}
