import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react'
import { Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { useFocusEffect, useNavigationState } from '@react-navigation/native'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import { SelectorContext } from '@contextState/selectors'
import {
  getFormikInitialValues,
  getFormikValidationSchema,
  getFormByActivityType,
  preventLeaveViewListener,
  removePreventLeaveViewListener,
  parseDataToSaveDraft,
  validateByTypeActivity,
  onPressEvidenceFile,
  parseActivityDataToFormik,
  parseDraftDataToFormik,
  getFilesDeleted,
  parseEvidencesToFiles,
  parseAndSetEvidencesToStorage,
  validateLocationInLot,
  recalculateIsInLot,
  parseDataToValidate,
  parseDataToValidateAchievementUnplanned,
} from './utils'
import {
  AgreementTypeLazyLoadEvidence,
  getMaximumSurfaceAllowed,
  surfaceIsLessThanMaximumAllowed,
  TypeAgreementNeedSurfaceFarm,
  validateCompanyCountryHasOutlierOff,
} from '../../../../../modules/common/utils'
import { agreementsAndEvidence } from '../../activityCreate/v1/utils'
import useNetwork from '@utils/network'
import useModal from '@hooks/useModal'
import useActionSheetManagement from '@hooks/useActionSheetManagement'
import useOfflineCommon from '@offline/queries/common'
import useOfflineDrafts from '@offline/queries/drafts'
import useOfflineCrops from '@offline/queries/crops'
import {
  insertDraft,
  updateDraft,
  getDraftById,
  deleteDraft,
  getUserActive,
  deleteUserActive,
  deleteFilesInStorage,
  getDraftsVerificationByCropIdAndIdentifier,
} from '@services/firebase'
import activityTypesConstants from '@constants/activityTypes'
import verificationStatus from '@constants/verificationStatus'
import { verificationTypesActions } from '@store/actions/verificationTypes'
import Screen from './screen'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import { MODAL_ERRORS } from '@utils/constants'
import { searchErrorInAcVerification } from '@modules/common/utils'
import { activityNotValidate } from '@services/analytics'
import useValidatePin from '@hooks/useValidatePin'
import { useAuth, useModalAnimated } from '@common/hooks'
import { useStandardDeviation, useChangeOutliersHarvest } from '@common/hooks'
import { truncateSurface } from '@common/utils/truncateSurface'
import { HttpStatusCode, parentTypes } from '@common/constants'
import useFetchFarmListByCrop from '@modules/farms/screens/FarmsListSelectByCrop/hooks/useFetchFarmListByCrop'
import { setItemsSelection, startDataSelection } from '@store/slices'
import { useSelector } from 'react-redux'
import RouterNames from '@constants/routerNames'
import { ESubtypeAgreements } from '@common/enum'
import { setActivityTemporalData } from '@store/slices/activity-temporal-data.slice'
import { CropContext } from '@contextState/crop'

const ActivityCreate = ({ navigation, route }) => {
  const { t, i18n } = useContext(LanguageContext)
  const routesLength = useNavigationState((state) => state.routes.length)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)
  const { typeAgreements: agreementTypes } = useSelector(
    (state) => state.typeAgreementsReducer
  )
  const {
    verificationTypes,
    unitTypes,
    setShowDeleteIcon,
    onEnableDeleteIcon,
    onDisableDeleteIcon,
    showDeleteModal: isModalDeleteDraftVisible,
    closeDeleteModal: closeModalDeleteDraft,
    displayToggleModalNotification,
    subTypeActivities,
    unitTypesSupplies,
    fetchVerificationTypes,
    fetchTypeActivities,
    unitTypesVolume,
    errorDeepLink,
    evidenceCft,
    irrigationPowerSources,
    irrigationWaterSources,
    evidenceConcept,
    paddyWaterCultivations,
    paddyWaterPreseasons,
  } = useContext(CommonContext)
  const {
    clearSelectors,
    setCollaboratorSelector,
    setEstablishmentsSelector,
    setLotSelector,
    jsonWkt,
    setJsonWkt,
  } = useContext(SelectorContext)
  //const { setCurrentCropId } = useContext(CropContext)

  const { user } = useAuth()
  const { config } = user

  const companySelected = config?.companySelected || {}
  const country = companySelected?.country || {}
  const { alpha3Code } = country
  const {
    cropId: cropIdParam,
    activityId,
    draftId,
    tag,
    activityToDelete,
    notDeepLink,
    isNewActivity,
  } = useRef(route.params).current
  const getCropIdUrl = () => {
    //!IMPORTANT: Se vuelve a colocar para resolver un problema pero no es la solucion correcta
    //!IMPORTANT: Se debe arreglar la deuda tecnica de deeplink para que encuentre bien el cropId
    const segments = route.path.split('/')
    return segments[3]
  }

  const cropId = cropIdParam || getCropIdUrl()

  const isRealized = useRef(
    route.params.isRealized === 'true' || route.params.isRealized === true
  ).current
  const isFinished = useRef(
    route.params.isFinished === 'true' || route.params.isFinished === true
  ).current
  const isDirectAchievement = useRef(
    route.params.isDirectAchievement === 'true' ||
      route.params.isDirectAchievement === true
  ).current
  const canPlanning = useRef(
    route.params.canPlanning === 'true' || route.params.canPlanning === true
  ).current

  const activityType = activityTypes.find((element) => element.tag === tag)
  const dispatch = useDispatch()
  const [crop, setCrop] = useState({})
  const [activity, setActivity] = useState()
  const [draft, setDraft] = useState()
  const [isViewReady, setIsViewReady] = useState(false)
  const [dontShowGenerateCft, setDontShowGenerateCft] = useState(false)
  const [isViewReadOnly, setIsViewReadOnly] = useState(isRealized || isFinished)
  const [isOtherUserOnDraft, setIsOtherUserOnDraft] = useState(false)
  const [isSaveDraftButtonAvailable, setIsSaveDraftButtonAvailable] =
    useState(false)
  const [isSaveDraftButtonLoading, setIsSaveDraftButtonLoading] =
    useState(false)
  const [isValidateButtonAvailable, setIsValidateButtonAvailable] =
    useState(false)
  const [isValidateButtonVisible, setValidateButtonVisible] = useState(true)
  const [isDeclineButtonVisible, setDeclineButtonVisible] = useState(true)
  const [isDeclineButtonAvailable, setDeclineButtonAvailable] = useState(false)

  const [isValidateButtonLoading, setIsValidateButtonLoading] = useState(false)
  const [isSubmitValidation, setIsSubmitValidation] = useState(false)
  const [canShowOutlier] = useState(
    isConnected &&
      tag !== activityTypesConstants.ACT_VERIFICATION.key &&
      !validateCompanyCountryHasOutlierOff(alpha3Code)
  )

  const [hasSurfaceFarmForDeclaration, setHasSurfaceFarmForDeclaration] =
    useState(true)

  const [
    isAddVerificationFileButtonDisabled,
    setIsAddVerificationFileButtonDisabled,
  ] = useState(false)
  const [goBackWithoutSaveChangesEvent, setGoBackWithoutSaveChangesEvent] =
    useState(null)
  const [formikInitialValues, setFormikInitialValues] = useState(
    getFormikInitialValues({
      ...route.params,
      cropId,
      activityType,
      company: user.config.companySelected,
      locale: i18n.locale,
      isDirectAchievement,
      signers:
        tag === activityTypesConstants.ACT_VERIFICATION.key
          ? [
              {
                userId: user._id,
                fullName: `${user.firstName} ${user.lastName}`,
                email: user.email,
                type: user.type,
                identifier: user.identifier,
                selected: true,
              },
            ]
          : [],
    })
  )
  const [newValueVerification, setNewValueVerification] = useState(null)
  const [canSign, setCanSign] = useState(
    route.params.canSign === 'true' || route.params.canSign === true
  )
  const [isActivityNotValidate, setIsActivityNotValidate] = useState(true)

  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const {
    findDraftByIdOffline,
    insertDraftOffline,
    updateDraftOffLine,
    deleteDraftByIdOffline,
  } = useOfflineDrafts()
  const { findOneCropById } = useOfflineCrops()
  const { verifyExpirationPin, geTokenAuthPin } = useValidatePin()
  const {
    isModalVisible: isModalConfirmGoBackVisible,
    toggleModal: displayToggleModalConfirmGoBack,
    closeModal: closeModalConfirmGoBack,
  } = useModal()
  const {
    isModalVisible: isModalValidateVisible,
    toggleModal: displayToggleModalValidate,
    closeModal: closeModalValidate,
  } = useModal()
  const {
    isModalVisible: isModalVerifyPinVisible,
    toggleModal: toggleModalVerifyPin,
    setModalIsVisible: setModalVerifyPinVisible,
    closeModal: closeModalVerifyPin,
  } = useModal()
  const {
    isModalVisible: isModalEvidenceVisible,
    toggleModal: toggleModalEvidences,
    closeModal: closeModalEvidences,
  } = useModal()
  const {
    isModalVisible: isModalDestinationVisible,
    toggleModal: toggleModalDestination,
    closeModal: closeModalDestination,
  } = useModal()
  const {
    isModalVisible: isModalConfirmChangeVerification,
    toggleModal: toggleModalConfirmChangeVerification,
    closeModal: closeModalConfirmChangeVerification,
  } = useModal()
  const {
    isModalVisible: isModalValidateVerificationVisible,
    toggleModal: toggleModalValidateVerification,
    closeModal: closeModalValidateVerification,
  } = useModal()
  const {
    isModalVisible: isModalConfirmValidateVerificationVisible,
    toggleModal: toggleModalConfirmValidateVerification,
    closeModal: closeModalConfirmValidateVerification,
  } = useModal()

  const { isOpenModal, actionSheetRef, openActionSheet, closeActionSheet } =
    useActionSheetManagement()

  const {
    isValueOutsideMeanHarvest,
    canShowOutlierHarvest,
    changeOutLierHarvest,
    statusOutlier,
  } = useChangeOutliersHarvest()

  const { fetchFarm } = useFetchFarmListByCrop()

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: getFormikValidationSchema(t, tag, isDirectAchievement),
    enableReinitialize: false,
  })

  const {
    dirty,
    setFieldValue,
    values,
    resetForm,
    isValid,
    validateForm,
    setSubmitting,
    isSubmitting,
    setErrors,
    setFieldTouched,
  } = formik

  const {
    isModalVisible: isModalLoadingVisible,
    closeModal: closeModalLoading,
    title: titleModalLoading,
    subtitle: subtitleModalLoading,
    setModalIsVisible: setModalLoadingIsVisible,
  } = useModalAnimated({
    title: t('VIEWS.ACTIVITY_CREATE.MODAL_LOADING.COMPLETING_THE_APPLICATION'),
    subtitle: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MOMENTS'
    ),
    subtitleSecondary: t(
      'VIEWS.ACTIVITY_CREATE.MODAL_LOADING.PLEASE_WAIT_A_FEW_MORE_MOMENTS'
    ),
  })
  const { openBackdropStandardDeviation, ComponentStandarDeviation } =
    useStandardDeviation({ t })

  /**
   * INIT FUNCTIONS
   */
  useEffect(() => {
    if (isDirectAchievement && activityTypes?.length <= 0) {
      return fetchTypeActivities()
    }

    fetchInitData()

    return () => {
      clearSelectors()
    }
  }, [
    activityTypes,
    agreementTypes,
    verificationTypes,
    paddyWaterCultivations,
    paddyWaterPreseasons,
  ])

  useFocusEffect(
    useCallback(() => {
      if (errorDeepLink) {
        navigation.replace('ModalErrorDeepLinks', {
          error: errorDeepLink,
        })
      }
    }, [])
  )

  useFocusEffect(
    useCallback(() => {
      //setCurrentCropId(cropId)
    }, [isConnected, cropId])
  )

  /** Decline functions */

  const canShowDeclineButton = (defaultValue) => {
    setDeclineButtonVisible(
      tag !== activityTypesConstants.ACT_VERIFICATION.key && defaultValue
    )
  }

  const onSuccessDecline = () => {
    navigation.goBack()
  }

  /**
   * Recalculate isInLot
   */
  useEffect(() => {
    if (values?.lots?.value?.length) {
      recalculateEvidences()
    }
  }, [values?.lots])

  /**
   * VALIDATE MAXIMUM SURFACE ALLOWED
   */
  useEffect(() => {
    navigation.addListener('focus', validateMaximumSurfaceAllowed)
    validateMaximumSurfaceAllowed()
  }, [values.surface, navigation])

  /**
   * VALIDATE USER CAN VALIDATE/REJECT
   */
  useEffect(() => {
    if (!isConnected || !isRealized) {
      setDeclineButtonVisible(false)

      return
    }

    const isCurrentSigner = activity?.signers?.find(
      ({ userId }) => userId === user?._id
    )

    if (!isCurrentSigner || isCurrentSigner?.signed) {
      setValidateButtonVisible(false)
      setDeclineButtonVisible(false)

      return
    }

    if (isCurrentSigner && !isCurrentSigner?.signed) {
      setIsValidateButtonAvailable(true)
      setDeclineButtonAvailable(true)
      setValidateButtonVisible(true)
      setDeclineButtonVisible(true)
    }
  }, [isConnected, activity, user, isRealized, canSign])

  /**
   * DISABLE VALIDATE BUTTON IF SAVE DRAFT BUTTON IS LOADING
   */
  useEffect(() => {
    if (!isConnected) {
      return
    }

    if (
      isSaveDraftButtonLoading ||
      isOtherUserOnDraft ||
      !isValid ||
      !canSign
    ) {
      setIsValidateButtonAvailable(false)

      return
    }

    setIsValidateButtonAvailable(true)
  }, [
    isConnected,
    isSaveDraftButtonLoading,
    isValid,
    isOtherUserOnDraft,
    canSign,
  ])

  /**
   * DISABLE SAVE DRAFT BUTTON IF VALIDATE BUTTON IS LOADING
   */
  useEffect(() => {
    if (isValidateButtonLoading || isOtherUserOnDraft) {
      setIsSaveDraftButtonAvailable(false)
    } else {
      setIsSaveDraftButtonAvailable(true)
    }
  }, [isValidateButtonLoading, isOtherUserOnDraft])

  /**
   * ADD LISTENERS FOR PREVENT USER LEAVE SCREEN WITHOUT SAVE
   */
  useEffect(() => {
    if (!canSign || isSubmitValidation || isActivityNotValidate) {
      removePreventLeaveViewListener()

      return
    }

    preventLeaveViewListener(
      navigation,
      beforeRemoveListener,
      beforeunloadListener
    )
  }, [isActivityNotValidate, isSubmitValidation, canSign])

  /**
   * ABLE/DISABLE SAVE DRAFT BUTTON IF HAVE ANY CHANGE
   */
  useEffect(() => {
    if (formik.dirty) {
      setIsSaveDraftButtonAvailable(true)
    } else {
      setIsSaveDraftButtonAvailable(false)
    }
  }, [formik])

  /**
   * HIDE VALIDATE BUTTON IF USER IS NOT SIGNER
   * HIDE VALIDATE BUTTON IF ACTIVITY IS FINISHED
   * SHOW VALIDATE BUTTON IF ACTIVITY IS REALIZED OR IS DRAFT
   * SHOW/HIDE VALIDATE BUTTON IF IS OTHER USER ON DRAFT
   * SHOW/HIDE DELETE ICON IF IS OTHER USER ON DRAFT
   */
  useEffect(() => {
    if (!isViewReady) {
      return
    }

    if (isFinished) {
      setValidateButtonVisible(false)
      return
    }

    if (draftId) {
      setIsViewReadOnly(isOtherUserOnDraft || isRealized || isFinished)
      setShowDeleteIcon(!isOtherUserOnDraft)
      setValidateButtonVisible(!isOtherUserOnDraft)
    }
  }, [isViewReady, isOtherUserOnDraft, canSign])

  /**
   * HIDE DELETE DRAFT ICON IF USER LEAVE THE VIEW
   * ENABLE/DISABLE DELETE DRAFT ICON IF USER IS NOT CONNECTED OR LEAVE THE VIEW
   * TRIGGER EVENT FOR VERIFY IS ANY USER IS ALREADY ON DRAFT
   */
  useFocusEffect(
    useCallback(() => {
      validateForm()

      if (draftId) {
        if (isConnected) {
          const userFirebase = {
            _id: user._id,
            name: `${user.firstName} ${user.lastName}`,
          }

          getUserActive(draftId, userFirebase, disableScreen, enableScreen)
        } else {
          onDisableDeleteIcon()
        }
      }

      return () => {
        if (draftId) {
          setShowDeleteIcon(false)
          onEnableDeleteIcon()
        }
      }
    }, [isConnected, isValid])
  )

  useEffect(() => {
    return () => {
      if (isConnected && draftId) {
        deleteUserActive(draftId, user._id)
      }
    }
  }, [isConnected, draftId])

  useEffect(() => {
    if (
      verificationTypes.length &&
      tag === activityTypesConstants.ACT_VERIFICATION.key &&
      crop?.verificationTypes?.length
    ) {
      dispatch(
        verificationTypesActions.loadVerificationTypes({
          id: cropId,
          identifier: user.config.companySelected?.identifier,
          verificationTypes,
          verificationTypesCrop: crop?.verificationTypes,
        })
      )
    } else if (
      !verificationTypes.length &&
      tag === activityTypesConstants.ACT_VERIFICATION.key
    ) {
      fetchVerificationTypes()
    }
  }, [verificationTypes, crop])

  useEffect(() => {
    if (isSubmitting) {
      setModalLoadingIsVisible(true)
    } else {
      setModalLoadingIsVisible(false)
    }

    return () => {
      closeModalLoading()
    }
  }, [isSubmitting])

  useEffect(() => {
    if (values?.signers?.find(({ userId }) => userId === user._id)) {
      setCanSign(true)
    } else {
      setCanSign(false)
    }
  }, [values.signers, user])

  const searchDraftInVerification = async (currentCrop) => {
    const draftsByCrop = await getDraftsVerificationByCropIdAndIdentifier(
      cropId,
      user.config.companySelected?.identifier
    )
    const isError = searchErrorInAcVerification(currentCrop, draftsByCrop)
    if (isError === MODAL_ERRORS.EXIST_VERIFICATION_DRAFT) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.EXIST_VERIFICATION_DRAFT,
      })
      throw new Error(MODAL_ERRORS.EXIST_VERIFICATION_DRAFT)
    } else if (isError === MODAL_ERRORS.NO_LOTS_AVAILABLE) {
      navigation.navigate('ModalErrorDeepLinks', {
        error: MODAL_ERRORS.NO_LOTS_AVAILABLE,
      })
      throw new Error(MODAL_ERRORS.NO_LOTS_AVAILABLE)
    }
    return false
  }

  /**
   * FETCH INIT DATA
   */
  const fetchInitData = useCallback(async () => {
    try {
      setIsViewReady(false)

      const [crop, activity, draft] = await Promise.all([
        fetchCrop(),
        fetchActivity(),
        fetchDraft(),
      ])

      //si notDeepLink es falso quiere decir que entra de un deeplink y hay que hacer la validacion del fav button de verificaciones
      if (
        !notDeepLink &&
        tag === activityTypesConstants.ACT_VERIFICATION.key &&
        !activityId &&
        !draftId
      ) {
        await searchDraftInVerification(crop)
      }

      let newFormikInitialValues

      if (draftId) {
        if (!draft) {
          navigation.navigate('ModalErrorDeepLinks', {
            error: MODAL_ERRORS.NOT_AVAILABLE,
          })
          return
        }
        newFormikInitialValues = parseDraftDataToFormik({
          t,
          verificationTypes,
          unitTypes,
          agreementTypes,
          formikInitialValues,
          draft,
          subTypeActivities,
          tag,
          locale: i18n.locale,
          unitTypesVolume,
          irrigationPowerSources,
          irrigationWaterSources,
          evidenceConcept,
          paddyWaterCultivations,
          paddyWaterPreseasons,
        })

        setSelectors(crop, draft.lots, draft.signers)
      } else if (activityId) {
        if (!activity) {
          navigation.navigate('ModalErrorDeepLinks', {
            error: MODAL_ERRORS.NOT_AVAILABLE,
          })
          return
        }

        newFormikInitialValues = parseActivityDataToFormik({
          t,
          verificationTypes,
          unitTypes,
          agreementTypes,
          formikInitialValues,
          activity,
          subTypeActivities,
          tag,
          locale: i18n.locale,
          unitTypesSupplies,
          unitTypesVolume,
          irrigationPowerSources,
          irrigationWaterSources,
        })

        setSelectors(
          crop,
          activity.lotsWithSurface?.length
            ? activity.lotsWithSurface
            : activity.lots,
          activity.signers
        )
      }

      if (newFormikInitialValues) {
        setFormikInitialValues(newFormikInitialValues)

        resetForm({
          values: newFormikInitialValues,
        })

        setTimeout(validateForm)
      }

      setIsViewReady(true)
    } catch (error) {
      console.error('error en initialData', error)
    }
  }, [
    activityTypes,
    agreementTypes,
    verificationTypes,
    paddyWaterCultivations,
    paddyWaterPreseasons,
  ])

  /**
   * FETCH CROP
   */
  const fetchCrop = useCallback(async () => {
    try {
      let fetchCrop
      if (isConnected) {
        const response = await doRequest({
          method: 'GET',
          version: 'v2',
          url: `crops/${cropId}`,
          params: {
            companyId: user.config.companySelected?._id,
          },
          displayAlert: false,
        })
        fetchCrop = response.data
      } else {
        fetchCrop = await findOneCropById(cropId)
      }

      if (!fetchCrop) {
        navigation.navigate('ModalErrorDeepLinks', {
          error: MODAL_ERRORS.UNAUTHORIZED,
        })

        throw new Error(MODAL_ERRORS.NOT_AVAILABLE)
      }

      const me = fetchCrop.members.find((el) => el.user._id === user._id)

      if (!me) {
        navigation.navigate('ModalErrorDeepLinks', {
          error: MODAL_ERRORS.UNAUTHORIZED,
        })

        throw new Error(MODAL_ERRORS.UNAUTHORIZED)
      }

      setCrop(fetchCrop)

      return fetchCrop
    } catch (error) {
      console.error(error)

      let errorMessage

      if (error.status === HttpStatusCode.UNAUTHORIZED) {
        errorMessage = MODAL_ERRORS.UNAUTHORIZED
      } else {
        errorMessage = MODAL_ERRORS.ERROR_UNEXPECTED
      }

      navigation.navigate('ModalErrorDeepLinks', {
        error: errorMessage,
      })

      throw new Error(errorMessage)
    }
  }, [isConnected, cropId])

  /**
   * FETCH ACTIVITY
   */
  const fetchActivity = useCallback(async () => {
    if (!activityId) {
      return false
    }

    let activity

    if (isConnected) {
      const response = await doRequest({
        method: 'GET',
        url: `activities/${activityId}`,
      })

      activity = response.data
    } else {
      const offlineData = await showIndividuals('activities', activityId)

      activity = offlineData
    }

    setActivity(activity)

    return activity
  }, [isConnected])

  /**
   * FETCH DRAFT
   */
  const fetchDraft = useCallback(async () => {
    if (!draftId) {
      return false
    }

    let draft

    if (isConnected) {
      draft = await getDraftById(draftId)
    } else {
      const [errorFindDraft, responseDraft] = await findDraftByIdOffline(
        draftId
      )

      if (errorFindDraft) {
        alert(errorFindDraft)

        return
      }

      draft = responseDraft
    }

    setDraft(draft)

    return draft
  }, [isConnected])

  /**
   * SET DATA TO SELECTORS
   *
   * @param {*} signers
   */
  const setSelectors = useCallback(({ establishments }, lots, signers) => {
    const auxJsonWkt = {}

    setCollaboratorSelector(signers)

    const newEstablishments = establishments
      .map((element) => {
        const newLots = []

        element.lots.map((subElement) => {
          const lot = lots?.find(
            (subSubElement) =>
              subSubElement.lot?._id === subElement._id ||
              subSubElement._id === subElement._id
          )

          if (lot) {
            newLots.push({
              ...lot,
              ...lot.lot,
            })
            auxJsonWkt[subElement._id] = {
              wktFormat: subElement?.wktFormat,
              area: subElement?.area,
            }
          }
        })
        return {
          tag: element.tag,
          lots: newLots,
        }
      })
      .filter((element) => element.lots.length)
    setJsonWkt(auxJsonWkt)
    setEstablishmentsSelector(newEstablishments)
    setLotSelector(lots.map((element) => element._id))
  }, [])

  /**
   * DISABLE SCREEN
   *
   * @param {*} name
   */
  const disableScreen = useCallback((name) => {
    setIsOtherUserOnDraft(name)
  }, [])

  /**
   * ENABLE SCREEN
   */
  const enableScreen = useCallback((isOtherUserLeaveDraft) => {
    if (isOtherUserLeaveDraft) {
      fetchInitData()
      setIsViewReadOnly(false)
      setIsOtherUserOnDraft(false)
    } else {
      setShowDeleteIcon(true)
    }
  }, [])

  /**
   * VALIDATE IF USER ATTEMPT GO BACK WITHOUT SAVE USING NAVIGATOR IN THE APP
   *
   * @param {*} event
   * @returns
   */
  const beforeRemoveListener = useCallback(
    (event) => {
      if (event.data.action.type === 'RESET') {
        return
      }

      if (canSign && isConnected && isActivityNotValidate) {
        activityNotValidate({
          activityType: tag,
          date: new Date(),
          userId: user._id,
          email: user.email,
        })
      }
      if (!dirty || isSubmitValidation) {
        return
      }
      event.preventDefault()
      displayToggleModalConfirmGoBack()
      setGoBackWithoutSaveChangesEvent(event)
    },
    [dirty, isActivityNotValidate, isSubmitValidation]
  )

  /**
   * VALIDATE IF USER ATTEMPT LEAVE OR REFRESH WITHOUT SAVE ON WEB
   *
   * @param {*} event
   * @returns
   */
  const beforeunloadListener = useCallback(
    (event) => {
      if (canSign && isConnected && isActivityNotValidate) {
        activityNotValidate({
          activityType: tag,
          date: new Date(),
          userId: user._id,
          email: user.email,
        })
      }
      if (!dirty) {
        return
      }

      event.preventDefault()

      return (event.returnValue =
        t(
          'VIEWS'
        ).ACTIVITY_CREATE.MODAL_CONFIRM_GO_BACK_WITHOUT_SAVE_CHANGES.TEXT_2)
    },
    [isActivityNotValidate]
  )

  const validateMaximumSurfaceAllowed = () => {
    const { surface = 0, lots } = values
    const areaUnit = lots?.value[0]?.area
    if (!surface || !areaUnit) {
      return null
    }
    const surfaceMax = truncateSurface(getMaximumSurfaceAllowed(areaUnit))
    const isValidSurface = surfaceIsLessThanMaximumAllowed(surface, surfaceMax)
    if (!isValidSurface) {
      setTimeout(() => {
        setErrors({
          surface: t(
            'VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.SURFACE_FIELD.LIMIT',
            {
              surfaceMax,
              areaUnit,
            }
          ),
        })
      }, 100)
    }
  }

  /**
   * @function  farmsSelectedCallback
   * @description Processes the information of the selected field in the selector of fields
   */
  const farmsSelectedCallback = (farms) => {
    setHasSurfaceFarmForDeclaration(true)
    const resultFarms = []
    let resultSurface = 0
    let totalSurfaceFarm = 0
    let resultLots = []
    for (let index = 0; index < farms.length; index++) {
      const farmSelected = farms[index]
      const { lots } = farmSelected
      const lotsFetch =
        lots ??
        values.lots?.value?.filter((lot) => lot.farm === farmSelected.farm)
      const resultLotsByFarm = lotsFetch.map((element) => ({
        _id: element._id,
        surface: element.surface,
        tag: element.tag,
        wktFormat: element.wktFormat,
        area: element.area ?? element.areaUnit ?? undefined,
        farm: farmSelected.farm,
      }))
      resultLots = [...resultLots, ...resultLotsByFarm]

      totalSurfaceFarm = truncateSurface(
        resultLotsByFarm.reduce(
          (accumulator, currentValue) => accumulator + currentValue.surface,
          0
        )
      )

      if (
        TypeAgreementNeedSurfaceFarm[values?.typeAgreement?.key] &&
        !farmSelected?.surfaceFarm
      ) {
        totalSurfaceFarm = 0
        setHasSurfaceFarmForDeclaration(false)
      }

      resultSurface += totalSurfaceFarm
      resultFarms.push({ ...farmSelected, lots: undefined })
    }
    const labelLots =
      resultFarms.length > 1
        ? t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FARM_FIELD.PLURAL', {
            num: resultFarms.length,
          })
        : t('VIEWS.ACTIVITY_CREATE.AGREEMENT_FORM.FARM_FIELD.SINGULAR', {
            num: resultFarms.length,
          })
    setFieldValue('lots', {
      value: resultLots,
      label: labelLots,
    })
    setFieldTouched('surface')
    setFieldValue('surface', String(resultSurface))
    setFieldValue('farms', resultFarms)
  }

  /**
   * GO TO SELECT LOTS AND SURFACE
   */
  const onPressLotsInput = useCallback(() => {
    //Reiniciamos el estado si selecciona otro campo
    setHasSurfaceFarmForDeclaration(true)

    if (tag === activityTypesConstants.ACT_AGREEMENTS.key) {
      if (!values?.typeAgreement?.key) {
        return
      }

      if (values?.typeAgreement?.parentType === parentTypes.CROP_FARM) {
        dispatch(startDataSelection({ itemsSelected: values.farmsSelected }))
        fetchFarm(cropId, activityId, values?.farm?.farmId).then((result) => {
          const { farms } = result
          const callback = (farmsSelected) => {
            farmsSelectedCallback(farmsSelected)
            navigation.pop(1)
          }
          dispatch(
            setItemsSelection({
              items: farms,
              callback,
              fieldNameRender: 'tag',
              fieldIsSelected: 'farm',
            })
          )
        })
        navigation.navigate('SelectItemsToParameters', {
          isViewReadOnly,
          multipleSelection: values?.typeAgreement?.multipleSelection,
          title: t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM'),
          textButton: t('NAVIGATION.STACK_NAVIGATOR.SELECT_FARM'),
        })
        return
      }
    }

    if (isViewReadOnly) {
      if (draftId) {
        return
      }

      if (activity.lotsWithSurface?.length === activity.lots.length) {
        navigation.navigate('ActivityMinimumUnitLot', {
          cropId,
          activityId,
          readOnly: isViewReadOnly,
        })
      } else {
        navigation.navigate('LotsSelectorModal', {
          id: activityId,
          type: 'activities',
          status: isViewReadOnly ? 'readOnly' : '',
        })
      }

      return
    }

    if (tag === activityTypesConstants.ACT_VERIFICATION.key) {
      dispatch(
        verificationTypesActions.setVerificationSelected(
          values.verificationType
        )
      )
    }

    if (tag === activityTypesConstants.ACT_AGREEMENTS.key) {
      if (!values?.typeAgreement?.key) {
        return
      }

      if (values?.typeAgreement?.subType === ESubtypeAgreements.FORM) {
        navigation.navigate('LotsSelectorModal', {
          ...route.params,
          id: cropId,
          cropId,
          type: 'crops',
          callback: (lots, numPop = 1) => {
            const newLabel =
              lots.length > 1
                ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
                : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')

            setFieldValue('lots', {
              value: lots,
              label: `${lots.length} ${newLabel}`,
            })

            const totalSurface = truncateSurface(
              lots.reduce(
                (acumulator, currentValue) => acumulator + currentValue.surface,
                0
              )
            )

            setFieldValue('surface', String(totalSurface))

            navigation.pop(numPop)
          },
        })

        return
      }

      if (values?.typeAgreement?.parentType === parentTypes.CROP_FARM) {
        navigation.navigate('FarmsListSelectByCrop', {
          ...route.params,
          farmId: values?.farm?._id,
          callback: (farm) => {
            const { lots, tag } = farm
            setFieldValue('lots', {
              value: lots,
              label: tag,
            })
            const totalSurface = truncateSurface(
              lots.reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.surface,
                0
              )
            )
            setFieldValue('surface', String(totalSurface))
            setFieldValue('farm', farm)
            navigation.pop(1)
          },
        })

        return
      }
    }

    navigation.navigate('ActivityLotsAdd', {
      ...route.params,
      hideSurfacePlanned: true,
      draftId,
      tag,
      isDirectAchievement,
      callback: (lots, numPop = 2) => {
        const newLabel =
          lots.length > 1
            ? t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL')
            : t('VIEWS.ACTIVITY_CREATE.MONITORING_FORM.FIELD_1.LABEL_ALT')

        setFieldValue('lots', {
          value: lots,
          label: `${lots.length} ${newLabel}`,
        })

        const totalSurface = truncateSurface(
          lots.reduce(
            (acumulator, currentValue) => acumulator + currentValue.surface,
            0
          )
        )

        setFieldValue('surface', String(totalSurface))

        navigation.pop(numPop)
      },
    })
  }, [
    isViewReadOnly,
    activity,
    values?.verificationType,
    values?.typeAgreement,
    values?.farms,
  ])

  const navigationBack = () => {
    if (routesLength === 1) {
      navigation.navigate(RouterNames.CROP_LIST)
      return
    }
    if (isNewActivity) {
      navigation.pop(2)
    } else {
      navigation.goBack()
    }
  }

  /**
   * SAVE DRAFT
   */
  const onPressSaveDraftButton = async () => {
    if (isSaveDraftButtonLoading) {
      return
    }

    setIsSaveDraftButtonLoading(true)

    const evidences = await parseAndSetEvidencesToStorage(
      isConnected,
      values.evidences
    )

    const dataToInsert = parseDataToSaveDraft({
      canPlanning,
      ...values,
      evidences,
      companyIdentifier: user.config.companySelected?.identifier,
      areaUnit: crop.areaUnit,
      type: activityType,
      isDirectAchievement,
    })

    const firebaseUser = {
      _id: user._id,
      name: `${user.firstName} ${user.lastName}`,
    }

    const hasCropOffline = crop.members?.find(
      (element) => element.user?._id === user._id && element.isOfflineEnabled
    )

    if (!draftId) {
      let newDraft

      if (isConnected) {
        newDraft = await insertDraft(dataToInsert, firebaseUser)
      }

      if (Platform.OS !== 'web' && (hasCropOffline || !isConnected)) {
        const [errorInsertDraftOffline] = await insertDraftOffline(
          {
            ...dataToInsert,
            isSynchronized: isConnected,
            id: newDraft?.id,
          },
          firebaseUser
        )

        if (errorInsertDraftOffline && !isConnected) {
          alert(errorInsertDraftOffline)

          return
        }
      }
    } else {
      if (isConnected) {
        deleteFilesInStorage(
          draft.draftGroupId,
          draftId,
          getFilesDeleted(formikInitialValues.evidences, values.evidences)
        )

        dataToInsert.isRejected = false
        dataToInsert.rejectedAt = undefined
        dataToInsert.rejectObservation = undefined
        await updateDraft(dataToInsert, firebaseUser)
      }

      if (Platform.OS !== 'web' && (hasCropOffline || !isConnected)) {
        const [errorUpdateDraftOffline] = await updateDraftOffLine(
          draftId,
          dataToInsert,
          firebaseUser
        )

        if (errorUpdateDraftOffline && !isConnected) {
          alert(errorUpdateDraftOffline)

          return
        }
      }
    }

    if (activityToDelete && isConnected) {
      await doRequest({
        method: 'DELETE',
        url: `activities/${activityToDelete}`,
      })
    }

    resetForm({
      values,
    })

    setIsSaveDraftButtonLoading(false)

    displayToggleModalNotification({
      text: t('VIEWS.ACTIVITY_CREATE.TEXT_7'),
      duration: 5000,
    })

    navigationBack()
  }

  /**
   *  Change Outlier UnitType EventChange.
   *
   * @param String value
   */
  const changeOutlierUnitType = (value) => {
    const {
      cropType: { key },
    } = crop
    const unit = value?.key
    const pay = values?.pay
    const cropType = key

    if (pay && unit && cropType) {
      changeOutLierHarvest(pay, unit, cropType)
    }
  }

  /**
   *  Change Outlier Pay Event Change.
   *
   * @param String value
   */
  const changePayAndRequestOutlier = (value) => {
    const {
      cropType: { key },
    } = crop
    const unit = values?.unitType?.key
    const cropType = key

    if (value && unit && cropType) {
      changeOutLierHarvest(value, unit, cropType)
    }
  }

  /**
   * SUBMIT VALIDATION
   */
  const onPressValidateButton = async () => {
    setIsSubmitValidation(true)
    if (tag !== activityTypesConstants.ACT_VERIFICATION.key && !isRealized) {
      const isPlanification = !isDirectAchievement && canPlanning
      const dataToValidate = !isPlanification
        ? parseDataToValidateAchievementUnplanned(values, user._id)
        : parseDataToValidate(values, user._id)
      let screensToReturn = 2

      if (!draft && !activityToDelete && isDirectAchievement) {
        screensToReturn = 3
      }

      const temporalData = {
        dataToValidate,
        isPlanification,
        cropName: crop.name,
        cropType: crop?.cropType?.key,
        evidences: values.evidences,
        displayDraftVersionModal: draft?.hasMoreVersion,
        activityToDelete,
        isDirectAchievement,
        screensToReturn,
        isDeepLink: !notDeepLink,
        draftId,
      }

      dispatch(setActivityTemporalData(temporalData))

      setIsSubmitValidation(false)

      navigation.navigate(RouterNames.ACTIVITY_CREATE_CONFIRM, {
        cropId,
        activityTypeTag: tag,
        isPlanification,
      })

      return
    }

    if (isValidateButtonLoading || isModalVerifyPinVisible) {
      return
    }

    if (draftId && draft?.hasMoreVersion) {
      displayToggleModalValidate()

      return
    }

    if (tag === activityTypesConstants.ACT_VERIFICATION.key && !isRealized) {
      toggleModalValidateVerification()

      return
    }

    if (tag === activityTypesConstants.ACT_VERIFICATION.key && isRealized) {
      toggleModalConfirmValidateVerification()

      return
    }

    if (canSign) {
      if (config.hasPin) {
        if (verifyExpirationPin()) {
          toggleModalVerifyPin(true)
        } else {
          const { validate, tokenPin } = await geTokenAuthPin()
          if (!validate) {
            toggleModalVerifyPin(true)
            return
          }
          onSuccessVerifyPin(tokenPin)
        }
      } else {
        navigation.navigate(RouterNames.CREATE_PIN, {
          callbackPin,
        })
        setIsSubmitValidation(false)
      }
      return
    }

    onValidate()
  }

  const callbackPin = async () => {
    const { tokenPin } = await geTokenAuthPin()
    onSuccessVerifyPin(tokenPin)
  }

  /**
   * CONFIRM VALIDATE DRAFT
   */
  const onPressConfirmValidate = async () => {
    closeModalValidate()

    if (tag === activityTypesConstants.ACT_VERIFICATION.key && !isRealized) {
      setTimeout(toggleModalValidateVerification, 100)

      return
    }

    if (canSign) {
      if (verifyExpirationPin()) {
        setTimeout(toggleModalVerifyPin, 100)
      } else {
        const { validate, tokenPin } = await geTokenAuthPin()
        if (!validate) {
          setTimeout(toggleModalVerifyPin, 100)
          return
        }
        onSuccessVerifyPin(tokenPin)
      }
      return
    }

    onValidate()
  }

  /**
   * CALLBACK AFTER VERIFY PIN
   *
   * @param {*} pinToken
   */
  const onSuccessVerifyPin = async (pinToken) => {
    closeModalVerifyPin()
    onValidate(pinToken)
  }

  /**
   * ON PRESS CANCEL BUTTON MODAL VERIFY PIN
   */
  const onPressCancelModalVerifyPin = () => {
    setModalVerifyPinVisible(false)
    setIsValidateButtonLoading(false)
  }

  /**
   * DO VALIDATION
   */
  const onValidate = async (pinToken) => {
    try {
      setIsActivityNotValidate(false)
      setIsValidateButtonLoading(true)
      setSubmitting(true)

      const files = await parseEvidencesToFiles(values.evidences)

      const newValues = {
        ...values,
        files,
      }

      await validateByTypeActivity({
        doRequest,
        tag,
        activityToDelete,
        isRealized,
        values: newValues,
        pinToken,
        isDirectAchievement,
        userId: user._id,
      })

      setIsValidateButtonLoading(false)

      resetForm()
      navigationBack()
    } catch (error) {
      alert(error.error)
    } finally {
      setIsValidateButtonLoading(false)
      closeModalVerifyPin()
      setSubmitting(false)
    }
  }

  /**
   * CONFIRM GO BACK WITHOUT SAVING CHANGES
   */
  const onPressConfirmGoBackWithoutSaveChanges = useCallback(() => {
    closeModalConfirmGoBack()

    navigation.dispatch(goBackWithoutSaveChangesEvent.data.action)
  }, [goBackWithoutSaveChangesEvent])

  /**
   * CONFIRM DELETE DRAFT
   */
  const onPressConfirmDeleteDraft = async () => {
    closeModalDeleteDraft()

    setIsViewReady(false)

    setShowDeleteIcon(false)

    deleteFilesInStorage(
      draft.draftGroupId,
      draftId,
      values.evidences
        .filter((element) => element.persisted)
        .map((element) => element.fileNamePath || element.name)
    )

    const hasCropOffline = crop.members?.find(
      (element) => element.user?._id === user._id && element.isOfflineEnabled
    )

    if (Platform.OS !== 'web' && hasCropOffline) {
      deleteDraftByIdOffline(draftId)
    }

    await deleteDraft(draftId, draft.draftGroupId)

    resetForm()

    setTimeout(navigation.goBack)
  }

  /**
   * GO TO ADD COLLABORATORS
   */
  const onPressAddCollaborator = useCallback(() => {
    navigation.navigate('CollaboratorsSelectorModal', {
      id: cropId,
      status: isViewReadOnly ? 'readOnly' : '',
      tag,
      callback: (signers) => {
        setFieldValue('signers', signers)
      },
    })
  }, [isViewReadOnly])

  const recalculateEvidences = () => {
    if (!values?.evidences.length) {
      return null
    }
    const auxEvidences = recalculateIsInLot(values, jsonWkt)
    setFieldValue('evidences', auxEvidences)
  }

  /**
   * ON PRESS ADD EVIDENCE
   *
   * @param {*} param0
   */
  const onPressAddEvidence = ({ values: valuesEvidences, location }) => {
    let isInLot

    if (values.lots.value.length && Object.keys(location).length) {
      isInLot = validateLocationInLot({
        location,
        lots: values.lots.value,
        jsonWkt,
      })
    }

    const fileExtension = valuesEvidences.file.name.substring(
      valuesEvidences.file.name.lastIndexOf('.'),
      valuesEvidences.file.name.length
    )

    const evidenceFileName = uuidv4() + fileExtension

    const resultEvidences = [
      ...values.evidences,
      {
        ...valuesEvidences,
        meta: {
          location,
        },
        settings: {
          isInLot: Boolean(isInLot),
          isValidateLot: valuesEvidences.isValidateLot,
        },
        type: valuesEvidences.file.type,
        name: valuesEvidences.file.name,
        fileNamePath: evidenceFileName,
        originalName: valuesEvidences.file.name,
        date: moment.isMoment(valuesEvidences.date)
          ? valuesEvidences.date.toDate()
          : valuesEvidences.date,
        user: {
          _id: user._id,
          firstName: user.firstName,
          lastName: user.lastName,
        },
      },
    ]

    setFieldValue('evidences', resultEvidences)
  }

  /**
   * ON PRESS REMOVE EVIDENCE
   *
   * @param {*} index
   */
  const onPressRemoveEvidence = useCallback(
    (index) => {
      const resultEvidences = values.evidences.filter(
        (element, key) => key !== index
      )
      setFieldValue('evidences', resultEvidences)
    },
    [values?.evidences]
  )

  /**
   * ON PRESS ADD DESTINATION
   *
   * @param {*} param0
   */
  const onPressAddDestination = useCallback(
    ({ values: valuesDestination }) => {
      const resultDestination = [...values.storages, valuesDestination]

      setFieldValue('storages', resultDestination)
    },
    [values?.storages]
  )

  /**
   * ON PRESS REMOVE DESTINATION
   *
   * @param {*} index
   */
  const onPressRemoveDestination = useCallback(
    (index) => {
      const resultDestination = values.storages.filter(
        (el, key) => key !== index
      )

      setFieldValue('storages', resultDestination)
    },
    [values?.storages]
  )
  /**
   * ON PRESS CANCEL VALIDATE VERSION
   */
  const onPressCancelValidateVersion = useCallback(() => {
    closeModalValidate()

    setIsValidateButtonLoading(false)
  }, [])

  const copyObservation = (value) => {
    setFieldValue('observation', value)
  }

  const deleteObservation = (index) => {
    const arrayAuxObservation = values.observations
    arrayAuxObservation.splice(index, 1)
    setFieldValue('observations', arrayAuxObservation)
  }

  const onSelectVerificationFile = (file) => {
    const fileExtension = file.name.substring(
      file.name.lastIndexOf('.'),
      file.name.length
    )

    const evidenceFileName = uuidv4() + fileExtension

    const verificationFile = {
      file,
      name: evidenceFileName,
      originalName: file.name,
      description: file.description,
      uploadedBy: {
        _id: user._id,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      uploadedAt: new Date(),
    }

    setFieldValue('verificationFile', verificationFile)

    setIsAddVerificationFileButtonDisabled(true)
  }

  const onPressRemoveVerificationFile = () => {
    setIsAddVerificationFileButtonDisabled(false)
    setFieldValue('verificationFile', undefined)
  }

  const onPressVerificationInvalidButton = () => {
    closeModalValidateVerification()

    setFieldValue('verificationStatus', verificationStatus.INVALID.key)

    setTimeout(toggleModalConfirmValidateVerification, 100)
  }

  const onPressVerificationValidButton = () => {
    closeModalValidateVerification()

    setFieldValue('verificationStatus', verificationStatus.VALID.key)

    setTimeout(toggleModalConfirmValidateVerification, 100)
  }

  const onPressConfirmConfirmValidateVerification = async () => {
    toggleModalConfirmValidateVerification()

    if (verifyExpirationPin()) {
      setTimeout(toggleModalVerifyPin, 500)
    } else {
      const { validate, tokenPin } = await geTokenAuthPin()

      if (!validate) {
        setTimeout(toggleModalVerifyPin, 500)

        return
      }

      onSuccessVerifyPin(tokenPin)
    }
  }

  const openConfirmationChangeVerification = (value) => {
    toggleModalConfirmChangeVerification()
    setNewValueVerification(value)
  }

  const confirmVerification = () => {
    clearSelectors()
    closeModalConfirmChangeVerification()

    const newFormikInitialValues = getFormikInitialValues({
      ...route.params,
      activityType,
      company: user.config.companySelected,
      locale: i18n.locale,
      isDirectAchievement,
    })

    setFormikInitialValues(newFormikInitialValues)

    resetForm({
      values: newFormikInitialValues,
    })

    setTimeout(validateForm)

    setTimeout(() => setFieldValue('verificationType', newValueVerification))
  }

  const setFieldEiq = (resultSupplies) => {
    setFieldValue('supplies', resultSupplies)

    if (tag === activityTypesConstants.ACT_APPLICATION.key) {
      const totalEiqAllSupplies = calculateEiq(resultSupplies, values.surface)
      const range = getEIQRange(totalEiqAllSupplies)
      setFieldValue('eiqSupplies', { totalEiqAllSupplies, range })
      setFieldValue('eiqRealized', totalEiqAllSupplies)
    }
  }

  const pressAddSupply = () => {
    navigation.navigate(RouterNames.SEARCHER_SUPPLIES, {
      id: crop._id,
      tag: route.params.tag,
      activity: route.params.activity,
      surface: values.surface,
      alphaCode: crop?.company?.country?.alphaCode,
      cropType: crop?.cropType?.key,
      activityType: route.params.tag,
      suppliesSelected: values?.supplies,
      callBack: (supply) => {
        let result = []

        if (values?.supplies.find(({ _id }) => _id === supply._id)) {
          result = values?.supplies.map((element) =>
            element._id !== supply._id ? element : supply
          )
        } else {
          result = [...values.supplies, supply]
        }

        setFieldEiq(result)
        navigation.pop(2)
      },
    })
  }

  const navigateToSupplyDetail = (supply, key) => {
    navigation.navigate(
      isRealized ? RouterNames.SUPPLY_DETAIL : RouterNames.SUPPLY_LOADER,
      {
        id: supply._id,
        item: Object.assign({}, supply),
        key: key,
        surface: values.surface,
        readOnly: isRealized,
        callbackDelete: (index) => {
          const resultSupplies = values?.supplies.filter(
            (item, i) => i !== index
          )
          setFieldEiq(resultSupplies)
          navigation.pop()
        },
        callBack: (supply) => {
          const resultSupplies = values?.supplies.map((item) =>
            item._id === supply._id
              ? {
                  ...item,
                  ...supply,
                }
              : item
          )
          setFieldEiq(resultSupplies)
          navigation.pop()
        },
      }
    )
  }

  const openModalDecline = () => {
    setIsSubmitValidation(true)
    openActionSheet()
  }

  const beforeActionClose = () => {
    setIsSubmitValidation(false)
  }
  const isDeclarationHasLazyLoadEvidence = (typeAgreement, evidences = []) => {
    const evidence = evidences.find((evidence) =>
      agreementsAndEvidence.SDP_REPORTS.includes(
        evidence?.evidenceConcept?.code
      )
    )
    if (!typeAgreement || evidence) {
      return false
    }
    return AgreementTypeLazyLoadEvidence[typeAgreement]
  }

  const goToEditFarm = () => {
    const { farms = [] } = values
    const { farm, farmUuid } = farms[0]
    if (farmUuid) {
      navigation.navigate(RouterNames.FARM_MAP_TO_URL_WEB_VIEW, {
        resource: 'editFarm',
        uuid: farmUuid,
        callback: () => {
          fetchFarm(cropId, activityId, farm).then((result) => {
            const { farms = [] } = result
            farmsSelectedCallback(
              farms.filter(
                (element) => element?.farm?.toString() === farm.toString()
              )
            )
          })
        },
      })
    }
  }

  return (
    <Screen
      isViewReady={isViewReady}
      isViewReadOnly={isViewReadOnly}
      tag={tag}
      activity={activity}
      isRealized={isRealized}
      isFinished={isFinished}
      formik={formik}
      form={getFormByActivityType({
        tag,
        formik,
        isViewReadOnly,
        onPressLotsInput,
        goToEditFarm,
        openConfirmationChangeField: openConfirmationChangeVerification,
        crop,
        isDirectAchievement,
        activityType,
        isConnected,
        outlierHarvestLevel: activity?.outlierHarvestLevel,
        canShowOutlier,
        changeOutlierUnitType,
        changePayAndRequestOutlier,
        isValueOutsideMeanHarvest,
        canShowOutlierHarvest,
        statusOutlier,
        openBackdropStandardDeviation,
        isNewActivity: !activityId,
        formikInitialValues,
        dontShowGenerateCft,
        setDontShowGenerateCft,
        alphaCodeCompany: alpha3Code,
      })}
      crop={crop}
      draft={draft}
      isOtherUserOnDraft={isOtherUserOnDraft}
      isValidateButtonAvailable={
        draft?.isRejected !== true &&
        isValidateButtonAvailable &&
        hasSurfaceFarmForDeclaration
      }
      isValidateButtonLoading={isValidateButtonLoading}
      onPressSaveDraftButton={onPressSaveDraftButton}
      isSaveDraftButtonAvailable={isSaveDraftButtonAvailable}
      isSaveDraftButtonLoading={isSaveDraftButtonLoading}
      onPressValidateButton={onPressValidateButton}
      isValidateButtonVisible={isValidateButtonVisible}
      isModalConfirmGoBackVisible={isModalConfirmGoBackVisible}
      closeModalConfirmGoBack={closeModalConfirmGoBack}
      onPressConfirmGoBackWithoutSaveChanges={
        onPressConfirmGoBackWithoutSaveChanges
      }
      isModalValidateVisible={isModalValidateVisible}
      onPressCancelValidateVersion={onPressCancelValidateVersion}
      onPressConfirmValidate={onPressConfirmValidate}
      isModalDeleteDraftVisible={isModalDeleteDraftVisible}
      closeModalDeleteDraft={closeModalDeleteDraft}
      onPressConfirmDeleteDraft={onPressConfirmDeleteDraft}
      isModalVerifyPinVisible={isModalVerifyPinVisible}
      toggleModalVerifyPin={toggleModalVerifyPin}
      onPressCancelModalVerifyPin={onPressCancelModalVerifyPin}
      onSuccessVerifyPin={onSuccessVerifyPin}
      onPressAddCollaborator={onPressAddCollaborator}
      signerQuantity={values?.signers?.length}
      isModalEvidenceVisible={isModalEvidenceVisible}
      toggleModalEvidences={toggleModalEvidences}
      closeModalEvidences={closeModalEvidences}
      onPressAddEvidence={onPressAddEvidence}
      evidences={values?.evidences}
      onPressRemoveEvidence={onPressRemoveEvidence}
      onPressEvidenceFile={onPressEvidenceFile}
      lots={values?.lots}
      isModalDestinationVisible={isModalDestinationVisible}
      toggleModalDestination={toggleModalDestination}
      closeModalDestination={closeModalDestination}
      onPressAddDestination={onPressAddDestination}
      storages={values?.storages}
      onPressRemoveDestination={onPressRemoveDestination}
      observations={values?.observations}
      deleteObservation={deleteObservation}
      copyObservation={copyObservation}
      isModalConfirmChangeVerification={isModalConfirmChangeVerification}
      closeModalConfirmChangeVerification={closeModalConfirmChangeVerification}
      confirmVerification={confirmVerification}
      isModalValidateVerificationVisible={isModalValidateVerificationVisible}
      closeModalValidateVerification={closeModalValidateVerification}
      isAddVerificationFileButtonDisabled={isAddVerificationFileButtonDisabled}
      onSelectVerificationFile={onSelectVerificationFile}
      verificationType={values?.verificationType}
      verificationFile={values?.verificationFile}
      onPressRemoveVerificationFile={onPressRemoveVerificationFile}
      onPressVerificationInvalidButton={onPressVerificationInvalidButton}
      onPressVerificationValidButton={onPressVerificationValidButton}
      isModalConfirmValidateVerificationVisible={
        isModalConfirmValidateVerificationVisible
      }
      closeModalConfirmValidateVerification={
        closeModalConfirmValidateVerification
      }
      onPressConfirmConfirmValidateVerification={
        onPressConfirmConfirmValidateVerification
      }
      modalConfirmDecline={{
        isOpenModal,
        actionSheetRef,
        openActionSheet: openModalDecline,
        closeActionSheet,
        onSuccess: onSuccessDecline,
        beforeActionClose,
        paramsRequest: {
          method: 'PUT',
          url: `activities/${activityId}/reject`,
          version: 'v2',
          data: {
            cropId,
          },
        },
      }}
      isDeclineButtonVisible={isDeclineButtonVisible}
      isDeclineButtonAvailable={isDeclineButtonAvailable}
      isDirectAchievement={isDirectAchievement}
      supplies={values?.supplies}
      navigateToSupplyDetail={navigateToSupplyDetail}
      areaUnit={crop.areaUnit}
      pressAddSupply={pressAddSupply}
      totalEiqAllSupplies={values?.eiqSupplies?.totalEiqAllSupplies}
      eiqRange={values?.eiqSupplies?.range}
      isModalLoadingVisible={isModalLoadingVisible}
      titleModalLoading={titleModalLoading}
      subtitleModalLoading={subtitleModalLoading}
      canShowOutlier={canShowOutlier}
      openBackdropStandardDeviation={openBackdropStandardDeviation}
      ComponentStandarDeviation={ComponentStandarDeviation}
      setDontShowGenerateCft={setDontShowGenerateCft}
      evidenceCft={evidenceCft}
      isDeclarationHasLazyLoadEvidence={isDeclarationHasLazyLoadEvidence}
      isNewActivity={!activityId}
    />
  )
}

ActivityCreate.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default ActivityCreate
