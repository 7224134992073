import { useContext } from 'react'
import { Platform, ScrollView, StyleSheet, View } from 'react-native'

import { LanguageContext } from '@contextState/language'

import ButtonCustom from '@components/common/ButtonCustom'
import { carbon50, primary500, white } from '@styles/palette'
import {
  GenericSupplyForm,
  GenericSupplyHeader,
} from '@modules/supplies/components'

import { useCreateGenericSupply } from '@modules/supplies/hooks'
import { generateSupplyName } from '@modules/supplies/utils'
import { Alert } from '@modules/crops/screens/CropList/v1/components'
import { gray300, red100, red700 } from '@common/styles'
import { hasChemicalCompositions } from '@modules/supplies/utils/hasChemicalComponent'

const CreateGenericSupply = () => {
  const { t } = useContext(LanguageContext)
  const {
    formik,
    formik: { values, handleSubmit, isValid, errors },
    options,
  } = useCreateGenericSupply()

  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.scrollView}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      >
        <GenericSupplyHeader
          supplyName={generateSupplyName(values.chemicalCompositions)}
        />

        {errors.chemicalCompositions &&
          typeof errors?.chemicalCompositions === 'string' &&
          hasChemicalCompositions(values.chemicalCompositions) && (
            <Alert
              message={errors.chemicalCompositions}
              containerStyle={styles.contentAlert}
              textStyle={styles.textAlert}
            />
          )}

        <GenericSupplyForm formik={formik} options={options} />
      </ScrollView>

      <ButtonCustom
        onPress={handleSubmit}
        styles={isValid ? styles.button : styles.buttonDisabled}
        labelStyle={styles.textButton}
        disabled={!isValid}
      >
        {t('VIEWS.SUPPLIES.CREATE_NEW_FERTILIZER')}
      </ButtonCustom>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: carbon50,
  },
  scrollView: {
    marginTop: 8,
  },
  button: {
    backgroundColor: primary500,
    marginVertical: 5,
    fontWeight: '700',
  },
  textButton: {
    color: white,
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 20,
  },
  buttonDisabled: {
    backgroundColor: gray300,
    marginVertical: 5,
  },
  contentAlert: {
    backgroundColor: red100,
    paddingVertical: 12,
    paddingHorizontal: 8,
    marginHorizontal: 0,
    marginBottom: 32,
  },
  textAlert: {
    color: red700,
  },
})

export default CreateGenericSupply
