import React, { useCallback, useContext, useState } from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'
import { PropTypes } from 'prop-types'
import { LanguageContext } from '@contextState/language'
import { CropContext } from '@contextState/crop'
import { CommonContext } from '@contextState/common'
import useOfflineCommon from '@offline/queries/common'
import useOfflineDrafts from '@offline/queries/drafts'
import useNetwork from '@utils/network'
import { getDraftsByActivityId } from '@services/firebase'
import { CropDescription } from '@common/components'
import {
  HeaderInfoAchievements,
  InfoDisplayActivity,
  CardAchievement,
  PlanningDescription,
  ListAchievements,
  HeaderAccordionPlanning,
} from './components'
import { buildEstablishmentDraft } from '@common/utils/buildEstablishmentDrafts'
import ButtonCustom from '@components/common/ButtonCustom'
import { gray12, whiteLight } from '@styles/palette'
import LoaderCenterContainer from '@components/loaders/LoaderCenterContainer'
import { useFocusEffect } from '@react-navigation/native'
import useModal from '@hooks/useModal'
import ModalConfirm from '@components/common/v1/ModalConfirm'
import { primary500, white200 } from '@styles/palette'
import activityTypes from '@constants/activityTypes'
import { useWorkOrder } from './hooks'
import { WorkOrder } from './components'
import { useSelector } from 'react-redux'
import { useAuth } from '@common/hooks'

const AchievementsActivityList = ({ route, navigation }) => {
  const { t, i18n } = useContext(LanguageContext)
  const { user } = useAuth()
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { isVerifier } = useContext(CropContext)
  const { subTypeActivities } = useContext(CommonContext)

  const { doRequest } = useNetwork()
  const { showIndividuals } = useOfflineCommon()
  const { findDraftsByActivityIdOffline } = useOfflineDrafts()

  const { isModalVisible, toggleModal, closeModal } = useModal()

  const [percent, setPercent] = useState(null)
  const [activity, setActivity] = useState(null)
  const [drafts, setDrafts] = useState(null)
  const [canWrite, setCanWrite] = useState(false)
  const [loading, setLoading] = useState(false)

  const { seeWorkOrder, shareWorkOrder } = useWorkOrder(
    t,
    activity,
    i18n.locale
  )

  const { activityId, cropId, tag } = route.params

  const haveEiq = tag === activityTypes.ACT_APPLICATION.key

  const thereAchievement = Boolean(activity?.achievements?.length)

  const signerAllAchievement = activity?.achievements?.every((achievement) =>
    achievement?.signers?.every((signer) => signer.signed)
  )

  const noneAchievementRejected = activity?.achievements?.every(
    (achievement) => !achievement.isRejected
  )

  const canFinishedActivity =
    isConnected &&
    !drafts?.length &&
    thereAchievement &&
    signerAllAchievement &&
    noneAchievementRejected

  const isFinished = Boolean(
    activity?.crop?.finished?.find(
      (element) => element === activity._id || element?._id === activity._id
    )
  )

  useFocusEffect(
    useCallback(() => {
      fetchActivity(activityId)
    }, [activityId, isConnected])
  )

  const isCanWrite = async (crop) => {
    const me = crop.members.find((el) => el.user === user._id)

    setCanWrite(
      me?.type !== 'MARKETER' && me?.type !== 'PROVIDER' && !isVerifier()
    )
  }

  const fetchActivity = async (id) => {
    try {
      setLoading(true)

      let result = null

      if (isConnected) {
        const { data } = await doRequest({
          method: 'GET',
          url: `activities/${id}`,
        })

        result = data

        setActivity(data)
      } else {
        const crop = await showIndividuals('crops', cropId)

        const offlineData = await showIndividuals('activities', id)

        if (!offlineData.establishments) {
          offlineData.auxEstablishments = crop.establishments
          offlineData.areaUnit = crop.areaUnit
        }

        if (!offlineData.achievements) {
          offlineData.achievements = []
        }

        offlineData.crop = crop

        result = offlineData

        setActivity(offlineData)
      }

      if (result && !isVerifier()) {
        setPercent(
          result?.achievements?.reduce((prev, el) => {
            return prev + el.percent
          }, 0)
        )

        isCanWrite(result.crop)

        if (isConnected) {
          const drafts = await getDraftsByActivityId(result._id)
          setDrafts(drafts)
        } else {
          const [errorFindDrafts, drafts] = await findDraftsByActivityIdOffline(
            result._id
          )
          if (errorFindDrafts) {
            alert(errorFindDrafts)
            return
          }
          setDrafts(drafts)
        }
      }

      setLoading(false)
    } catch (error) {
      console.error('error fetch activity')
    }
  }

  const onPressAchievement = (achievement) => {
    if (achievement.version) {
      navigation.navigate('AchievementsScreen', {
        id: route.params.id ?? cropId,
        activity: route.params.activity ?? activityId,
        tag: tag,
        draftId: achievement.id || achievement._id,
      })
    } else {
      navigation.navigate('CommonActivitiesDetail', {
        id: cropId,
        achievement: achievement._id,
        activity: activityId,
        mode: 'sowing',
        canWrite,
        tag: tag,
      })
    }
  }

  const onPressCreateAchievement = () => {
    if (isFinished) {
      return
    }

    navigation.navigate('AchievementsScreen', {
      id: cropId,
      activity: activityId,
      tag: tag,
    })
  }

  const onPressFinishButton = () => {
    toggleModal()
  }

  const onPressConfirmFinished = async () => {
    closeModal()
    setLoading(true)
    await doRequest({
      method: 'PATCH',
      url: `activities/${activityId}/finished`,
      version: 'v2',
    })
    fetchActivity(activityId)
  }

  const onBackActivity = () => {
    navigation.goBack()
  }

  if (loading) {
    return <LoaderCenterContainer />
  }

  return (
    <ScrollView style={styles.content}>
      {activity && drafts && (
        <View>
          <CropDescription crop={activity?.crop} />
          <PlanningDescription
            renderInfo={() => {
              if (!activity.establishments) {
                activity.establishments = buildEstablishmentDraft(
                  activity.lots,
                  activity.auxEstablishments
                )
              }
              return (
                <>
                  <InfoDisplayActivity
                    activity={activity}
                    t={t}
                    haveEiq={haveEiq}
                  />

                  <WorkOrder
                    t={t}
                    seeWorkOrder={seeWorkOrder}
                    shareWorkOrder={shareWorkOrder}
                    show={Boolean(activity?.workOrderNumber)}
                  />
                </>
              )
            }}
            renderHeader={({ arrowAngle, onPress, isOpened }) => {
              return (
                <HeaderAccordionPlanning
                  activity={activity}
                  t={t}
                  locale={i18n.locale}
                  arrowAngle={arrowAngle}
                  onPress={onPress}
                  isOpened={isOpened}
                  subTypeActivities={subTypeActivities}
                  tag={tag}
                  isConnected={isConnected}
                />
              )
            }}
          />
          <ListAchievements
            achievements={[...drafts, ...activity.achievements]}
            renderHeaderAchievement={() => (
              <HeaderInfoAchievements
                percent={percent}
                onPressCreateAchievement={onPressCreateAchievement}
                isFinished={isFinished}
                t={t}
              />
            )}
            renderAchievement={(achievement) => {
              if (achievement.version || !achievement.establishments) {
                achievement.establishments = buildEstablishmentDraft(
                  achievement.lots,
                  activity.establishments || activity.auxEstablishments
                )
              }
              return (
                <CardAchievement
                  achievement={achievement}
                  key={`achievement-${achievement._id}`}
                  t={t}
                  locale={i18n.locale}
                  onPressAchievement={onPressAchievement}
                  tag={tag}
                  isConnected={isConnected}
                  areaUnit={activity.areaUnit}
                  subTypeActivities={subTypeActivities}
                  haveEiq={haveEiq}
                  activityTypes={activityTypes}
                />
              )
            }}
          />
          <View style={styles.contentButton}>
            {!isFinished && (
              <ButtonCustom
                onPress={onPressFinishButton}
                styles={
                  canFinishedActivity
                    ? styles.outlineButton
                    : styles.buttonDisabled
                }
                labelStyle={
                  canFinishedActivity
                    ? styles.textOutlineButton
                    : styles.textButtonDisabled
                }
                disabled={!canFinishedActivity}
              >
                {t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.TEXT_1')}
              </ButtonCustom>
            )}
            <ButtonCustom
              onPress={onBackActivity}
              styles={[styles.button]}
              labelStyle={styles.textButton}
            >
              {t('VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.TEXT_2')}
            </ButtonCustom>
          </View>

          <ModalConfirm
            visible={isModalVisible}
            onClose={closeModal}
            onConfirm={onPressConfirmFinished}
            title={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.CONFIRM_FINISHED.TEXT_1',
              { activity: activity.name }
            )}
            contentText={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.CONFIRM_FINISHED.TEXT_2'
            )}
            confirmText={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.CONFIRM_FINISHED.TEXT_3'
            )}
            cancelText={t(
              'VIEWS.ACHIEVEMENTS_ACTIVITY_LIST.CONFIRM_FINISHED.TEXT_4'
            )}
          />
        </View>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 10,
  },
  contentButton: {
    marginVertical: 25,
  },
  button: {
    marginVertical: 5,
  },
  buttonDisabled: {
    backgroundColor: whiteLight,
    borderColor: gray12,
    borderWidth: 1,
  },
  textButtonDisabled: {
    color: gray12,
  },
  outlineButton: {
    backgroundColor: white200,
    borderColor: primary500,
    borderWidth: 1,
    marginTop: 5,
  },
  textOutlineButton: {
    color: primary500,
  },
})

AchievementsActivityList.propTypes = {
  navigation: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
}

export default AchievementsActivityList
