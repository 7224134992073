import { Formik } from 'formik'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useFocusEffect } from '@react-navigation/core'
import { View, ScrollView, StyleSheet } from 'react-native'
import { Button, List } from 'react-native-paper'
import CommonForm from '@components/activities/CommonForm'
import CollaboratorsButton from '@components/collaborators/CollaboratorsButton'
import ButtonCustom from '@components/common/ButtonCustom'
import CommonList from '@components/common/CommonList'
import Layout from '@components/common/Layout'
import { CommonContext } from '@contextState/common'
import { CropContext } from '@contextState/crop'
import { SelectorContext } from '@contextState/selectors'
import useOfflineCommon from '../offline/queries/common'
import useOfflineCrops from '../offline/queries/crops'
import useOfflineQueue, { QUEUE_TYPES } from '../offline/queries/queue'
import { ObjectId } from '@utils/common'
import useNetwork from '@utils/network'
import { calculateEiq, getEIQRange } from '@utils/eiq'
import EiqCardSimple from '@components/common/v1/EiqCardSimple'
import { LanguageContext } from '@contextState/language'
import { validateDateIsOlder } from '@utils/date'
import { SUPPLY } from '@constants/iconGuide'
import { getDescriptionSupplySelect } from '@utils/supply'
import { formatDateMoment } from '@utils/date'
import { capitalizeFirstLetter } from '@utils/strings'
import { roundNumber } from '@utils/number'
import { MIN_CONSIDERATION_LENGTH } from '@utils/constants'
import { carbon600 } from '@styles/palette'
import { SIZES } from '@common/components/icons/interfaces'
import { EActivityTypes } from '@common/enum'
import { IconBox } from '@modules/supplies/components'
import { useDispatch, useSelector } from 'react-redux'
import { setActivityTemporalData } from '@store/slices/activity-temporal-data.slice'
import RouterNames from '@constants/routerNames'

function CommonActivities({ route, navigation }) {
  const { currentCrop, setCurrentCropId } = useContext(CropContext)
  const dispatch = useDispatch()
  const { fetchTypeActivities } = useContext(CommonContext)
  const { activityTypes } = useSelector((state) => state.activityTypesReducer)
  const { loading } = useNetwork()

  const {
    supplySelector,
    collaboratorSelector,
    lotSelector,
    clearSelectors,
    lotSelectorData,
  } = useContext(SelectorContext)
  const [tillage, setTillage] = useState(false)
  const [totalEiqAllSupplies, setTotalEiqAllSupplies] = useState(0)
  const { isConnected } = useSelector((state) => state.connectionReducer)
  const { changeActivityStatusOrder, addToCropStatus } = useOfflineCrops()
  const { storeQueueItem } = useOfflineQueue()
  const { storeIndividual } = useOfflineCommon()
  const [eiqRange, setEiqRange] = useState('VERY_LOW')
  const [surface, setSurface] = useState(0)
  const { t, i18n } = useContext(LanguageContext)
  const { areaUnit, cropTypeName, screensToReturn = 3 } = route.params
  const cropSurface = route.params.surface
  const activityType = activityTypes.find(
    (element) => element.tag === route.params.tag
  )

  useEffect(() => {
    if (route.params.tag === 'ACT_TILLAGE') {
      setTillage(true)
    }

    return () => {
      clearSelectors()
    }
  }, [])

  useEffect(() => {
    if (activityTypes?.length <= 0) {
      fetchTypeActivities()
    }
  }, [isConnected, activityTypes])

  useFocusEffect(
    useCallback(() => {
      setCurrentCropId(route?.params?.id)
    }, [isConnected, route?.params?.id])
  )

  useEffect(() => {
    if (supplySelector && surface) {
      setTotalEiqAllSupplies(calculateEiq(supplySelector, surface))
    }
  }, [supplySelector, surface])

  useEffect(() => {
    setEiqRange(getEIQRange(totalEiqAllSupplies))
  }, [totalEiqAllSupplies])

  function supplyNavigation() {
    navigation.navigate(RouterNames.SEARCHER_SUPPLIES, {
      id: route.params.id,
      tag: route.params.tag,
      surface,
      alphaCode: currentCrop?.company?.country?.alphaCode,
      cropType: currentCrop?.cropType.key,
      activityType: route.params.tag,
      suppliesSelected: supplySelector,
    })
  }

  async function onSubmit(values) {
    const data = {
      name: `${activityType.label}`,
      dateStart: values.dateStart.value,
      dateEnd: values.dateEnd.value,
      surface: Number(values.surface.replace(',', '.')),
      type: activityType.value,
      tag: route.params.tag,
      crop: route.params.id,
      lots: lotSelector,
      considerations: values.considerations,
      signers: collaboratorSelector.map((el) => {
        delete el.signed
        return el
      }),
      status: 'PLANNED',
      supplies: supplySelector.map((el) => ({
        ...el,
        typeId: el.typeId._id,
        supply: el.supplyId || el._id,
        code: el.code,
        brand: el?.brand || undefined,
      })),
    }

    if (values.subTypeActivity?.value) {
      data.subTypeActivity = values.subTypeActivity.value
    }

    if (isConnected) {
      const temporalData = {
        dataToValidate: {
          ...data,
        },
        isPlanification: true,
        cropName: currentCrop.name,
        supplies: supplySelector,
        currentActivity: route.params.activity,
        screensToReturn,
      }

      dispatch(setActivityTemporalData(temporalData))

      return navigation.navigate(RouterNames.ACTIVITY_CREATE_CONFIRM, {
        cropId: route.params.id,
        activityTypeTag: route.params.tag,
        isPlanification: true,
      })
    }

    const formData = new FormData()

    formData.append('data', JSON.stringify(data))

    if (!route.params.activity) {
      const _id = ObjectId()

      const newData = { _id, ...data }

      await addToCropStatus(route.params.id, 'toMake', {
        ...newData,
        lots: lotSelectorData,
      })

      await storeIndividual('activities', _id, {
        ...newData,
        lots: lotSelectorData,
      })

      await storeQueueItem(
        newData,
        {
          method: 'POST',
          url: 'activities',
          needsSerialize: true,
        },
        QUEUE_TYPES.COMMON_CREATE
      )
    } else {
      await changeActivityStatusOrder(
        route.params.id,
        route.params.activity,
        'pending',
        'toMake'
      )

      await storeQueueItem(
        data,
        {
          method: 'PUT',
          url: `activities/${route.params.activity}`,
          needsSerialize: true,
        },
        QUEUE_TYPES.COMMON_UPDATE
      )
    }

    navigation.pop(screensToReturn)
  }

  function enableValidate(values) {
    if (
      !values.subTypeActivity ||
      !(values.lots !== '' && values.surface !== '') ||
      (supplySelector.length === 0 && !tillage) ||
      collaboratorSelector.length <= 1
    ) {
      return true
    }
    if (values.dateStart && values.dateEnd) {
      if (
        !validateDateIsOlder(values.dateStart?.value, values.dateEnd?.value)
      ) {
        return true
      }
    } else {
      return true
    }
    if (
      values.considerations.length > 0 &&
      values.considerations.length <= MIN_CONSIDERATION_LENGTH
    ) {
      return true
    }
    return false
  }

  function navigateToSupplyDetail(supply, key) {
    navigation.navigate(RouterNames.SUPPLY_LOADER, {
      id: supply.supplyId,
      key: key,
      item: supply,
      surface,
    })
  }

  const textTitleCrop = `${cropTypeName} ${capitalizeFirstLetter(
    formatDateMoment(currentCrop?.dateCrop, 'MMM/YYYY', i18n.locale)
  )} ${t('VIEWS.COMMON_ACTIVITIES.TEXT_6')} ${capitalizeFirstLetter(
    formatDateMoment(currentCrop?.dateCrop, 'MMM/YYYY', i18n.locale)
  )}`.split('.')

  return (
    <Formik
      initialValues={{
        lots: { value: '', label: '' },
        surface: '',
        dateStart: { value: '', label: '' },
        dateEnd: { value: '', label: '' },
        subTypeActivity: { value: '', label: '' },
        considerations: '',
      }}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        isSubmitting,
        setFieldTouched,
        errors,
        touched,
      }) => {
        return (
          <View style={styles.formInner}>
            {currentCrop && (
              <List.Item
                style={styles.listItem}
                title={textTitleCrop}
                description={`${roundNumber(cropSurface)} ${areaUnit} - ${
                  currentCrop?.company?.name
                }`}
              />
            )}
            <ScrollView contentContainerStyle={styles.container}>
              <Layout>
                <CommonForm
                  setFieldValue={setFieldValue}
                  navigation={navigation}
                  route={route}
                  values={values}
                  setSurface={setSurface}
                  activityType={activityType}
                  areaUnit={areaUnit}
                  setFieldTouched={setFieldTouched}
                  errors={errors}
                  touched={touched}
                />
                {!tillage && (
                  <Button
                    disabled={lotSelector.length === 0 || !surface}
                    onPress={supplyNavigation}
                    labelStyle={
                      lotSelector.length === 0 || !surface
                        ? styles.textDisabled
                        : styles.text
                    }
                  >
                    {t('VIEWS.COMMON_ACTIVITIES.TEXT_3')}
                  </Button>
                )}
                {activityType?.tag === 'ACT_APPLICATION' && (
                  <EiqCardSimple
                    title={`${t(
                      'VIEWS.COMMON_ACTIVITIES.TEXT_4'
                    )} ${totalEiqAllSupplies}`}
                    range={eiqRange}
                    tag='ACT_APPLICATION'
                  />
                )}
                {supplySelector.map((element, key) => {
                  return (
                    <CommonList
                      key={key}
                      index={key}
                      onPress={() => navigateToSupplyDetail(element, key)}
                      img={SUPPLY[element?.typeId?.code] || SUPPLY['default']}
                      iconRender={
                        element.isGeneric || element?.supply?.isGeneric
                          ? () => (
                              <IconBox
                                iconName={EActivityTypes.ACT_FERTILIZATION}
                                size={SIZES.MEDIUM}
                                color={carbon600}
                                style={styles.iconRender}
                              />
                            )
                          : null
                      }
                      data={getDescriptionSupplySelect(element, areaUnit)}
                    />
                  )
                })}
                <CollaboratorsButton
                  title={t('COMPONENTS.COLLABORATORS_BUTTON.COLLABORATORS')}
                  quantity={collaboratorSelector.length}
                  onPress={() => {
                    navigation.navigate('CollaboratorsSelectorModal', {
                      id: route.params.id,
                      status: '',
                    })
                  }}
                />
              </Layout>
            </ScrollView>
            <ButtonCustom
              isLoading={loading}
              disabled={isSubmitting || enableValidate(values)}
              onPress={handleSubmit}
              styles={
                isSubmitting || enableValidate(values)
                  ? styles.buttonDisabled
                  : styles.button
              }
              labelStyle={
                isSubmitting || enableValidate(values)
                  ? styles.textButtonDisabled
                  : styles.textButton
              }
            >
              {t('VIEWS.COMMON_ACTIVITIES.TEXT_5')}
            </ButtonCustom>
          </View>
        )
      }}
    </Formik>
  )
}

const styles = StyleSheet.create({
  container: {},
  formInner: {
    flex: 1,
    padding: 16,
  },
  listItem: { paddingLeft: 0 },
  button: { backgroundColor: '#4CAF50' },
  buttonDisabled: { backgroundColor: '#81C784' },
  textButtonDisabled: { color: 'rgba(255, 255, 255, .5)' },
  textButton: { color: 'white' },
  textDisabled: { color: '#A5D6A7' },
  text: { color: '#4CAF50' },
  iconRender: {
    width: 50,
    height: 50,
  },
})

export default CommonActivities
