import React from 'react'
import Constants from 'expo-constants'
import { gray400 } from '@common/styles'
import { LanguageContext } from '@contextState/language'
import { TimeLineEvent } from '@modules/AppIntegrator/components/TimeLine'
import {
  alertText,
  carbon100,
  carbon300,
  carbon50,
  cherry100,
  cherry50,
  primary500,
  white,
} from '@styles/palette'
import {
  Dimensions,
  Image,
  Linking,
  Platform,
  ScrollView,
  Text,
} from 'react-native'
import { FC, useContext, useCallback, useState, useEffect } from 'react'
import { View } from 'react-native-animatable'
import { ESCreenWidth } from '@common/enum'
import ButtonCustom from '@components/common/ButtonCustom'
import { useDispatch } from 'react-redux'
import useNetwork from '@utils/network'
import { setWebViewValues } from '@store/slices'
import { EAppIntegrationStatus } from '@common/enum/appIntegrationStatus'
import { useFocusEffect } from '@react-navigation/native'
import StyleSheet from 'react-native-media-query'
import { getStyles } from './functions/getStyles'
import RouterNames from '@constants/routerNames'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { encrypt } from '@utils/crypto'
import { useAuth } from '@common/hooks'

export const AppIntegratorDetailsScreen: FC<any> = ({ navigation, route }) => {
  const {
    params: { pluginKey, isS2, plugin: pluginMock },
  } = route

  const { loginSuccessful } = route.params
  const { user } = useAuth()

  const { t, i18n } = useContext(LanguageContext)
  const dispatch = useDispatch()
  const { doRequest } = useNetwork()
  const [appService, setAppService] = useState({
    name: '',
    category: '',
    icon64: '',
    description: '',
    connected: false,
    status: [],
    redirectUri: '',
    _id: '',
  })
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [userLeafId, setuserLeafId] = useState('')
  const [getPlugins, setGetPlugins] = useState(false)
  const {
    firstName,
    lastName,
    email,
    phone,
    config: {
      companySelected: { address, plugins = [] },
    },
  } = user

  const isSynchronized = appService?.status?.some(
    (status: any) => status?.key === EAppIntegrationStatus.SYNCHRONIZED
  )

  const isPendingValidation = appService?.status?.some(
    (status: any) => status?.key === EAppIntegrationStatus.PENDING_VALIDATION
  )

  const isConnected = appService?.status?.some((status: any) =>
    Object.values(EAppIntegrationStatus).some(
      (integrationStatus: any) => integrationStatus === status?.key
    )
  )

  const dataIngestUrl =
    Constants.expoConfig?.extra?.DATA_HUB_URL ||
    'https://data-ingest-middle-ui-qa.ucrop.it'

  useFocusEffect(
    useCallback(() => {
      const fetchData = async () => {
        if (!isS2) {
          const response = await doRequest({
            method: 'get',
            url: `ms-integrator/plugins/${pluginKey}`,
          })

          setAppService(response.data)
        } else {
          setAppService(pluginMock)
        }
      }

      fetchData()
      return () => true
    }, [getPlugins])
  )

  const fecthCreateUser = async () => {
    const response = await doRequest({
      method: 'post',
      url: `ms-integrator-post/data-injection/create/user`,
      data: {
        name: `${firstName} ${lastName}`,
        email: email,
        phone: phone,
        address: address,
      },
    })

    const {
      data: { id },
    } = response.data

    setuserLeafId(id)
  }

  const fecthInjectData = async () => {
    const params = {
      pluginKey,
    } as any

    navigation.navigate(RouterNames.COMPANY_APP_INTEGRATION_DETAIL, params)
    setGetPlugins(true)
  }

  useEffect(
    useCallback(() => {
      if (loginSuccessful) {
        fecthCreateUser()
      }
    }, [loginSuccessful]),
    []
  )

  useEffect(
    useCallback(() => {
      if (userLeafId) {
        fecthInjectData()
      }
    }, [userLeafId]),
    [userLeafId]
  )

  if (!appService?.name) {
    return null
  }

  const handleConnectApplication = async () => {
    setButtonDisabled(true)
    const response = await doRequest({
      method: 'post',
      url: `ms-integrator/data-injection/leaf/auth`,
      headers: {
        Provider: 'john-deere',
      },
    })

    const { url } = response.data

    if (Platform.OS === 'web') {
      if (url) {
        window.location.href = url
      }
    } else {
      const dataToSend = {
        url: url,
        onMessage: () => {},
        redirectUri: appService?.redirectUri,
      }
      dispatch(setWebViewValues(dataToSend))
      navigation.navigate(
        RouterNames.COMPANY_APP_INTEGRATION_WEB_VIEW,
        dataToSend
      )
    }
  }

  const handleValidate = async () => {
    setButtonDisabled(true)
    const {
      config: {
        companySelected: { identifier, _id: companyId },
        roleSelected: { value },
      },
    } = user

    const token = await AsyncStorage.getItem('USER_TOKEN')
    const param = encrypt(
      JSON.stringify({
        identifier: identifier,
        token: token,
        role: value,
        companyId,
      })
    )
    const url = `${dataIngestUrl}/home?data=${String(param)}`

    if (url) {
      Linking.openURL(url).catch((err) => console.error('Error:', err))
    }
  }

  const windowWidth = Dimensions.get('window').width

  const { button, label, id } = getStyles(isConnected, isSynchronized)

  return (
    <>
      <View
        dataSet={{ media: ids.container }}
        style={
          Platform.OS === 'web' ? styles.container : styles.containerMobile
        }
      >
        <ScrollView>
          <Image
            source={{
              uri: appService?.icon64,
            }}
            style={styles.providerImage}
          />

          <View
            dataSet={{ media: ids.headContainer }}
            style={Platform.OS === 'web' ? styles.headContainer : {}}
          >
            <Text style={styles.name}>{appService?.name}</Text>
            <Text style={styles.category}>
              {appService?.category[i18n.locale]}
            </Text>
            <Text style={styles.description}>
              {appService?.description[i18n.locale]}
            </Text>
          </View>

          <View style={styles.timeLineContainer}>
            <TimeLineEvent
              appData={appService?.status}
              key={String(appService?._id)}
            />
          </View>
          <>
            <View style={styles.buttonContainer}>
              {!isConnected && (
                <ButtonCustom
                  dataSet={{ media: id }}
                  onPress={handleConnectApplication}
                  disabled={buttonDisabled}
                  contentStyle={{ height: 38 }}
                  styles={styles.button}
                  labelStyle={styles.textButton}
                >
                  {t(
                    'COMPONENTS.APP_INTEGRATOR.COMPONENTS.APP_INTEGRATOR_DETAILS.CONNECT'
                  )}
                </ButtonCustom>
              )}
            </View>
          </>

          <View style={styles.bottom}>
            {isPendingValidation && (
              <ButtonCustom
                dataSet={{ media: id }}
                onPress={handleValidate}
                contentStyle={{ height: 38 }}
                styles={styles.button}
                labelStyle={styles.textButton}
              >
                {t(
                  'COMPONENTS.APP_INTEGRATOR.COMPONENTS.APP_INTEGRATOR_DETAILS.VALIDATE'
                )}
              </ButtonCustom>
            )}

            {isConnected && (
              <View style={styles.bottomDisconnect}>
                <ButtonCustom
                  dataSet={{ media: id }}
                  disabled={isConnected || buttonDisabled}
                  onPress={handleConnectApplication}
                  contentStyle={{ height: 38 }}
                  styles={button}
                  labelStyle={label}
                >
                  {t(
                    'COMPONENTS.APP_INTEGRATOR.COMPONENTS.APP_INTEGRATOR_DETAILS.DISCONNECT'
                  )}
                </ButtonCustom>
              </View>
            )}
          </View>
        </ScrollView>
      </View>
    </>
  )
}

const { styles, ids } = StyleSheet.create({
  headContainer: {
    '@media (min-width: 1300px)': {
      width: 740,
    },
    '@media  (min-width: 620px) and (max-width: 960px) ': {
      width: 600,
    },
  },
  buttonContainer: {
    paddingTop: 80,
    flex: 1,
  },
  timeLineContainer: {
    paddingRight: 24,
    paddingLeft: 5,
    paddingHorizontal: 24,
    backgroundColor: carbon50,
    paddingBottom: 95,
  },
  container: {
    flex: 1,
    paddingRight: 24,
    paddingHorizontal: 24,
    paddingTop: 20,
    backgroundColor: carbon50,
    paddingLeft: 180,
    '@media (max-width: 960px)': {
      paddingLeft: 24,
    },
  },
  containerMobile: {
    flex: 1,
    paddingRight: 24,
    paddingLeft: 16,
    paddingHorizontal: 24,
    paddingTop: 20,
    backgroundColor: carbon50,
  },
  containerButton: {
    flex: 1,
    paddingBottom: 80,
    justifyContent: 'flex-end',
  },
  providerImage: {
    width: 64,
    height: 64,
  },
  name: {
    fontSize: 24,
    marginTop: 16,
    fontWeight: '700',
    lineHeight: 32,
  },
  category: {
    color: gray400,
    fontWeight: '400',
    marginTop: 8,
    lineHeight: 24,
  },
  description: {
    fontSize: 16,
    color: '#000',
    fontWeight: '400',
    marginTop: 16,
    lineHeight: 24,
  },
  button: {
    width: 224,
    height: 38,
  },
  textButton: {
    color: white,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disabledButton: {
    backgroundColor: carbon100,
    borderWidth: 1,
    top: '40%',
    right: 0,
    width: 224,
    height: 38,
  },
  textDisabledButton: {
    color: carbon300,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disconnectedButton: {
    backgroundColor: cherry50,
    borderColor: cherry100,
    borderWidth: 1,
    top: '40%',
    right: 0,
    width: 224,
    height: 38,
  },
  textDisconnectedButton: {
    color: alertText,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disabledMobileButton: {
    backgroundColor: carbon100,
    borderWidth: 1,
    right: 0,
  },
  textDisabledMobileButton: {
    color: carbon300,
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '500',
    lineHeight: 30,
    textAlign: 'center',
  },
  disconnectedMobileButton: {
    backgroundColor: cherry50,
    borderColor: cherry100,
    borderWidth: 1,
  },
  textDisconnectedMobiledButton: {
    color: alertText,
    fontFamily: 'Inter',
    fontSize: 16,
    fontWeight: '600',
    lineHeight: 24,
    textAlign: 'center',
  },
  bottom: {
    bottom: 110,
  },
  bottomDisconnect: {
    top: 90,
    paddingTop: 180,
  },
})
