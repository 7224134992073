import { useContext, useEffect, useRef, useState } from 'react'
import { fetchPaddyWaterCultivations } from '../services/fetchPaddyWaterCultivations'
import { fetchPaddyWaterPreseasons } from '../services/fetchPaddyWaterPreseasons'
import {
  SelectDropdownInterface,
  ValueAndKeyLabelInterface,
} from '@common/interfaces'
import { CommonContext } from '@contextState/common'
import { LanguageContext } from '@contextState/language'

const TIME_FLOOD_UNDER_CROP = '124'
const UNIT_TIME_KEY = 'day'

export const useFloodForm = (formik: any) => {
  const { i18n }: any = useContext(LanguageContext)
  const { setFieldValue, values, setValues } = formik
  const { unitTypes }: any = useContext(CommonContext)
  const unitTimeRef: any = useRef(null)
  const [paddyWaterCultivations, setPaddyWaterCultivations] = useState<
    SelectDropdownInterface[]
  >([])
  const [paddyWaterPreseasons, setPaddyWaterPreseasons] = useState<
    SelectDropdownInterface[]
  >([])
  const [unitTimes, setUnitTimes] = useState<SelectDropdownInterface[]>([])

  const formatArraySelect = (options: ValueAndKeyLabelInterface[]) => {
    return options.map((item) => ({
      value: item._id,
      label: item.keyLabel,
    }))
  }

  const fetchInfoFlood = async () => {
    const resultPaddyWaterCultivations = await fetchPaddyWaterCultivations()
    const resultPaddyWaterPreseasons = await fetchPaddyWaterPreseasons()
    setPaddyWaterCultivations(formatArraySelect(resultPaddyWaterCultivations))
    setPaddyWaterPreseasons(formatArraySelect(resultPaddyWaterPreseasons))
  }

  const setDefaultValues = () => {
    if (!unitTimeRef.current) return
    setFieldValue('timeFloodUnderCrop', TIME_FLOOD_UNDER_CROP)
    const unitTime = unitTypes.find(
      (unitType: any) => unitType.key === UNIT_TIME_KEY
    )
    unitTimeRef.current.reset()
    setFieldValue('unitTime', {
      value: unitTime._id,
      label: unitTime.name[i18n.locale],
    })
  }

  useEffect(() => {
    fetchInfoFlood()
  }, [i18n.locale])

  useEffect(() => {
    if (!i18n?.locale) return
    const unitTypesFlood = unitTypes.filter((unitType: any) => unitType.cftId)
    const resultUnitTye = unitTypesFlood.map((unitType: any) => ({
      value: unitType._id,
      label: unitType.name[i18n.locale],
    }))
    setUnitTimes(resultUnitTye)
  }, [unitTypes, i18n])

  const setValuesActivityFlood = (params: any) => {
    const unitTime = unitTimes.find(
      (unit: any) => unit.value === params.unitTime
    )
    const paddyWaterPreseason = paddyWaterPreseasons.find(
      (preseason) => params.paddyWaterPreseason === preseason.value
    )
    const paddyWaterCultivation = paddyWaterCultivations.find(
      (cultivation) => params.paddyWaterCultivation === cultivation.value
    )
    setValues({
      ...values,
      paddyWaterCultivation,
      paddyWaterPreseason,
      unitTime,
      timeFloodUnderCrop: String(params.timeFloodUnderCrop),
    })
  }

  useEffect(() => {
    if (
      values.paramsActivityFlood &&
      !values.paddyWaterPreseason?.value &&
      !values.paddyWaterCultivation?.value &&
      !values.unitTime?.value &&
      paddyWaterPreseasons.length &&
      paddyWaterCultivations.length &&
      unitTimes.length
    ) {
      setValuesActivityFlood(values.paramsActivityFlood)
    }
  }, [values, paddyWaterPreseasons, paddyWaterCultivations, unitTimes])

  return {
    paddyWaterCultivations,
    paddyWaterPreseasons,
    unitTimes,
    setDefaultValues,
    unitTimeRef,
  }
}
