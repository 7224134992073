import { useEffect, useState } from 'react'
import Constants from 'expo-constants'
import { Linking, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useCompanyInfo } from './useCompanyInfo'
import { useNavigation } from '@react-navigation/native'
const STORIES_URL = Constants.expoConfig.extra.FIELD_STORY_URL ?? ''

export enum MESSAGE_KEY {
  GO_TO_FIELD_STORY = 'GO_TO_FIELD_STORY',
  NUE_LINK_TO = 'NUE_LINK_TO',
}

interface QueryParams {
  cropId: string
  lotId?: string
}

export const useStoriesWebView = (path: string, query: QueryParams) => {
  const navigation = useNavigation()
  const { identifier: taxID } = useCompanyInfo()

  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [url, setUrl] = useState<string | null>(null)
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      setAccessToken(token)
    })()
  }, [])

  useEffect(() => {
    makeWebViewUrl()
    return () => {}
  }, [accessToken])

  const makeWebViewUrl = () => {
    if (!accessToken) return null

    const params = {
      ...query,
      taxID,
      accessToken,
      isApp: 'true',
      date: `${new Date().getTime()}`,
    }

    const searchParams = new URLSearchParams(params)
    const queryParams = searchParams.toString()

    setUrl(`${STORIES_URL}/${path}?${queryParams}`)

    setIsReady(true)
  }

  const handleIFrameMessages = (event: any) => {
    const data = Platform.OS === 'web' ? event.data : event.nativeEvent.data

    if (typeof data !== 'string') {
      return
    }

    let dataWebView

    try {
      dataWebView = JSON.parse(data)
    } catch (error) {
      return
    }

    if (!dataWebView) {
      return
    }

    switch (dataWebView.key) {
      case MESSAGE_KEY.GO_TO_FIELD_STORY:
        // @ts-ignore
        navigation?.push('FieldStory', {
          id: query.cropId,
          fieldStory: true,
          lotId: dataWebView?.data?.queryParams?.lotId,
        })
        break
      case MESSAGE_KEY.NUE_LINK_TO:
        if (dataWebView?.data?.url) {
          Linking.openURL(dataWebView.data.url)
        }
        break

      default:
        break
    }

    return dataWebView
  }

  return {
    accessToken,
    url,
    isReady,
    handleIFrameMessages,
  }
}
