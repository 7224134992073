import { useEffect, useState } from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'
import { useSelector } from 'react-redux'
import { useAnimatedStyle } from 'react-native-reanimated'

import { CustomDrawer } from '../modules/drawer/components/index'
//import { MainStackNavigator } from './StackNavigator'
import { useAuth, useResponsive } from '../modules/common/hooks'
import RouterNames from '../constants/routerNames'
import { HeaderOnlyBack } from '@common/components'
import Header from '../components/common/Header'

import { getCurrentRouteName } from './utils/getCurrentRouteName'
import { canSeeFarms } from '@utils/common'
import { maxWidthDrawer } from '@modules/drawer/constants'
import { ProfileStack } from '@modules/navigator/profile'
import { NotificationStack } from '@modules/navigator/notification'
import { FarmStack } from '@modules/navigator/farm'
import { SettingsStack } from '@modules/navigator/settings'
import { CompanyStack } from '@modules/navigator/company'
import { SetupStack } from '@modules/navigator/setup'
import { Platform } from 'react-native'
import { TraderStack } from '../modules/navigator/traders'
import { PolygonsTraderStack } from '../modules/navigator/polygons-trader'
import { RoleEnum } from '@common/enum'
import { QRStack } from '@modules/navigator/qr'
import { CropStack } from '@modules/navigator/crop'
import { LicenseStack } from '@modules/navigator/license'
import QuickSightTrader from '@modules/traders/screens/quick-sight-trader'
import CreatePin from '@modules/pin/screens/CreatePin'
import CognitoCode from '@modules/authentication/screens/CognitoCode/v1'
import WebView from '@modules/webView/screens/WebView'

const Drawer = createDrawerNavigator()

/**
 *
 * @param {string} role
 * @param {string} defaultRoute
 * @returns string
 */
export const getInitialRoute = (role, defaultRoute) => {
  switch (role) {
    case RoleEnum.PRODUCTIVE_UNIT:
      return RouterNames.POLYGONS_TRADER

    case RoleEnum.EXPORTER_CIRCUIT:
      return RouterNames.TRADERS

    case RoleEnum.QR_VIEW:
    case RoleEnum.QR_ADMIN:
      return RouterNames.QR

    default:
      return defaultRoute
  }
}

const DrawerNavigator = () => {
  const { isScreenDesktop } = useResponsive()

  const [isOpen, setIsOpen] = useState(isScreenDesktop)

  const { config, isPendingAuthorization } = useAuth()
  const { roleSelected, companySelected } = config
  const { isConnected } = useSelector((state) => state.connectionReducer)

  const hasCompany = Boolean(companySelected)
  const canSeeDrawFields = canSeeFarms({ roleSelected })

  const routeUcropIt =
    canSeeDrawFields && !isPendingAuthorization && hasCompany && isConnected
      ? RouterNames.FARMS
      : RouterNames.CROPS

  const initialRoute = getInitialRoute(
    config?.roleSelected?.value,
    routeUcropIt
  )

  const viewStylesWeb = useAnimatedStyle(() => {
    const widthValue = isOpen ? maxWidthDrawer : 73

    return {
      transitionDuration: '100ms',
      width: widthValue,
    }
  })

  const viewStylesMobile = {
    width: maxWidthDrawer,
    padding: 0,
  }

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    setIsOpen(isScreenDesktop)
  }, [isScreenDesktop])

  return (
    <Drawer.Navigator
      initialRouteName={initialRoute}
      backBehavior={!hasCompany ? 'initialRoute' : 'history'}
      drawerContent={(props) => {
        let currentRoute = {}

        props.state.routes.forEach((route, index) => {
          if (props.state.index === index) {
            currentRoute = route
          }
        })

        //Validate if a main route exists - Deeplink workaround to back button redirecting to home
        if (
          props.state?.routes[1]?.name === RouterNames.CROPS &&
          props.state?.routes[1]?.state &&
          props.state?.routes[1]?.state?.routes[0]?.name !== RouterNames.CROPS
        ) {
          props.state.routes[1].state.routes.unshift({
            name: RouterNames.CROPS,
          })
        }

        const currentRouteName = !hasCompany
          ? RouterNames.CROPS
          : getCurrentRouteName({ currentRoute })

        return (
          <CustomDrawer
            {...props}
            currentRouteName={currentRouteName}
            hasCompany={hasCompany}
            setDrawerOpen={toggleDrawer}
            isCompleteMode={isOpen || Platform.OS !== 'web'}
          />
        )
      }}
      screenOptions={{
        drawerType: Platform.OS === 'web' ? 'permanent' : 'front',
        drawerStyle: Platform.OS === 'web' ? viewStylesWeb : viewStylesMobile,
      }}
    >
      <Drawer.Screen
        name={RouterNames.PROFILE}
        options={{ headerShown: false }}
        initialParams={{}}
        component={ProfileStack}
      />

      <Drawer.Screen
        name={RouterNames.NOTIFICATION}
        options={{ headerShown: false }}
        initialParams={{}}
        component={NotificationStack}
      />

      {hasCompany ? (
        <Drawer.Screen
          name={RouterNames.FARMS}
          options={{ headerShown: false }}
          initialParams={{}}
          component={FarmStack}
        />
      ) : null}

      <Drawer.Screen
        name={RouterNames.CROPS}
        options={{ headerShown: false }}
        initialParams={{}}
        component={CropStack}
      />

      <Drawer.Screen
        name={RouterNames.LICENSES}
        options={{ headerShown: false }}
        initialParams={{}}
        component={LicenseStack}
      />

      <Drawer.Screen
        name={RouterNames.COMPANY}
        options={{ headerShown: false }}
        initialParams={{}}
        component={CompanyStack}
      />

      <Drawer.Screen
        name={RouterNames.SETUP}
        options={{ headerShown: false }}
        initialParams={{}}
        component={SetupStack}
      />

      <Drawer.Screen
        name={RouterNames.SETTINGS}
        options={{ headerShown: false }}
        initialParams={{}}
        component={SettingsStack}
      />

      <Drawer.Screen
        name={RouterNames.TRADERS}
        options={{ headerShown: false }}
        initialParams={{}}
        component={TraderStack}
      />

      <Drawer.Screen
        name={RouterNames.QR}
        options={{ headerShown: false }}
        initialParams={{}}
        component={QRStack}
      />

      <Drawer.Screen
        name={RouterNames.POLYGONS_TRADER}
        options={{ headerShown: false }}
        initialParams={{}}
        component={PolygonsTraderStack}
        header={null}
      />

      <Drawer.Screen
        name={RouterNames.QUICK_SIGHT_TRADER}
        options={{ headerShown: false }}
        initialParams={{}}
        component={QuickSightTrader}
        header={null}
      />

      <Drawer.Screen
        name={RouterNames.CREATE_PIN}
        options={{
          title: '',
          header: () => null,
        }}
        component={CreatePin}
      />

      <Drawer.Screen
        name='CognitoCode'
        component={CognitoCode}
        options={{
          title: '',

          header: (props) => (
            <HeaderOnlyBack {...props} title={''} testID='back_button' />
          ),
        }}
      />

      <Drawer.Screen
        name='WebView'
        component={WebView}
        options={{
          title: '',
          header: (props) => {
            return (
              <Header
                {...props}
                title={
                  props?.route?.params?.title ??
                  props.scene?.route?.params?.title
                }
                goBack
              />
            )
          },
        }}
      />
    </Drawer.Navigator>
  )
}

export default DrawerNavigator
