import { useState, useContext, useEffect } from 'react'
import { StyleSheet, View, Text } from 'react-native'
import { Snackbar, ActivityIndicator } from 'react-native-paper'
import { NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import AsyncStorage from '@react-native-async-storage/async-storage'
import * as Linking from 'expo-linking'
import { useDispatch, useSelector } from 'react-redux'
import { useFirebase } from '@common/hooks'

import RouterNames from '@constants/routerNames'
import SupplyLoaderScreen from '@modules/activities/screens/SupplyLoader/v1/SupplyLoaderScreen'
import { LanguageContext } from '@contextState/language'
import { CommonContext } from '@contextState/common'
import Header from '@components/common/Header'
import DrawerNavigator from './DrawerNavigator'
import LotsSelectorScreen from '../screens/LotsSelectorScreen'
import CollaboratorsSelectorScreen from '../screens/CollaboratorsSelectorScreen'
import SupplySelector from '../screens/v1/supplySelector'
import ActivityLotsAdd from '../screens/v1/ActivityLotsAdd'
import CommonLoader from '@modules/loaders/screens/CommonLoader/v1'
import {
  white,
  graySnackBar,
  carbon300,
  carbon100,
  Orange500,
  secondary600,
} from '@styles/palette'
import { useAuth } from '@common/hooks'
import {
  useCropTypes,
  useTypeAgreements,
  useActivityTypes,
  useStorageTypes,
} from '@modules/common/hooks'

import { AuthenticationStack } from '@modules/navigator/authentication'
import { CompanyOnboardingStack } from '@modules/navigator/company-onboarding'

/******************************
 *         ACTIVITIES         *
 *****************************/
import ActivityMinimumUnitLot from '../screens/v1/ActivityMinimumUnitLot'
import SupplyDetail from '../screens/v1/supplyDetail/index'

import CreatePin from '@modules/pin/screens/CreatePin'

import { LayoutWithOutResponsive } from '@common/components/index'

/******************************
 *         SUPPLIES           *
 *****************************/
import CreateGenericSupply from '@modules/supplies/screens/CreateGenericSupply/v1/index.tsx'
import { setIsAuth } from '@store/slices'

const Stack = createStackNavigator()

const linking = {
  prefixes: [
    Linking.createURL('/'),
    'https://developer-crop-story.ucrop.it',
    'https://app.ucrop.it',
    'https://developer-sn-deeplinking.ucrop.it',
  ],
  config: {
    screens: {
      Authentication: {
        path: '/authentication',
        screens: {
          SignIn: '/signin',
          SignUp: '/signup',
          VerifyCode: '/verify-code',
        },
      },
      OnboardingCompany: {
        path: '/company/onboarding',
        screens: {
          WelcomeCompany: '/welcome',
          ValidateTaxId: '/verify-identifier',
          AddCompanyScreen: '/add',
          SuccessAddCompany: '/success',
          CollaboratorsListNewCompany: '/collaborators',
        },
      },
      SupplySelectorExternal: '/supplies-selector',
      ActivityLotsAddExternal:
        '/activity-lots-add/crop/:cropId/achievement/lots-add',
      Drawer: {
        path: '',
        screens: {
          Profile: {
            path: '/profile',
            screens: {
              Profile: '/',
            },
          },
          Notification: {
            path: '/notifications',
            screens: {
              NotificationList: '/list',
              NotificationFilters: '/filters',
              CommonActivitiesDetail: '/activity-legacy/sign',
              ActivityUpdate: '/activity/sign',
            },
          },
          Crops: {
            path: '/crops',
            screens: {
              CropList: '/list',
              SearcherSupplies: '/search-supplies',
              SelectItemsWithMultiLevels: '/select-items-with-multiLevels',
              CropCreate: '/create',
              AchievementsActivityList: '/achievements-activity-list',
              //CompanyAdd: '/company-add',
              CreateGenericSupplyV2: '/create-generic-supply-v2',
              CropDetail: '/:id',
              //Licenses: '/crop/:cropId/licenses',
              Activities: '/activities/:id',
              ActivityList: '/:cropId/activities',
              FilterActivities: '/:cropId/activities/filter',
              Agreement: '/activities/:id/agreement',
              AgreementEdit: '/activities/:id/agreement/:agreement',
              Monitoring: '/activities/:id/monitoring',
              MonitoringEdit: '/activities/:id/monitoring/:monitoring',
              Harvest: '/activities/:id/harvest',
              HarvestEdit: '/activities/:id/harvest/:harvest',
              CommonActivitiesDetail:
                'activities/:id/:mode/common/detail/:achievement/:activity/:canWrite?',
              CommonActivities: '/activities/:id/:mode/:tag/common/:activity?',
              CommonActivitiesEdit:
                '/activities/:id/:mode/common/edit/:activity',
              CommonOptionalPlanned: {
                path: '/activities/:id/:mode/:tag/common/optionalPlanned/:activity?',
              },
              AchievementsScreen:
                '/activities/:id/common/achievement/:activity/:tag?',
              ActivityCreate: '/:cropId/activity/create',
              ActivityUpdate: '/:cropId/activity/update',
              ActivityFinished: '/:cropId?/activity/:activityId/finished',
              ActivityLotsAdd:
                '/:cropId/activity/:activityId/achievement/lots-add',
              ActivityMinimumUnitLot:
                '/:cropId/activity/:activityId/achievement/lots-add/edit-Surface',
              ActivityValidation: '/activity-validation',
              ActivityCreateConfirm: '/:cropId/activity/create/confirm',
              ActivityApplicationDetails:
                '/:cropId/activities/application/:activityId',
              DetailsPlanning: '/:cropId/activity/:activityId/detailsPlanning',
              DetailsRealization:
                '/:cropId/activity/:activityId/achievement/detailsRealization',
              CropFarmList: {
                path: '/:cropId/farms',
              },
              CropFarmFieldDetails: {
                path: '/:cropId/farms/:farmId/fields/:fieldId',
              },
              FarmLotEditName: {
                path: '/:cropId/farms/:farmUuid/lots/:lotUuid/edit',
              },
            },
          },
          // FarmList: {
          //   path: 'farms',
          //   screens: {
          //     SelectItemsToParameters: '/selectToParameters',
          //     FarmList: '/list',
          //     FarmLotsAdd: '/create/lots-add',
          //     FarmDetails: '/:farmId',
          //     FarmEditName: '/:farmUuid/edit',
          //     FarmLotList: '/:farmId/lots/:name',
          //     FarmLotDetails: '/:farmId/lots/:lotId',
          //     FarmLotEditName: '/:farmUuid/lots/:lotUuid/edit',
          //   },
          // },
          Farms: {
            path: '/farms',
            screens: {
              FarmMap: '/map',
            },
          },
          Licenses: {
            path: '/licenses',
            screens: {
              LicenseList: '/list',
              LicenseDetail: '/:licenseId',
              LicenseDetailReadOnly: '/:licenseId/read-only',
              LicenseSignV2: '/:licenseId/sign',
              LicenseLotsAdd: '/:licenseId/fields-add',
              SummaryLicenseLots: '/:licenseId/fields-summary',
              SummaryLandscapeSustainability:
                '/:licenseId/landscape-sustainability',
            },
          },
          Company: {
            path: '/Company',
            screens: {
              CompanyList: '/List',
              CompanyListUcropit: '/Advised/List',
              CompanyAdd: '/Add',
              CollaboratorsListNewCompany: '/Add/Collaborators/List',
              CompanyDetail: '/Detail',
              CollaboratorListCompany: '/Collaborators/List',
              AddCollaborator: '/Collaborators/Add',
              AddAdviser: '/Collaborators/Adviser/Add',
              CompanyServiceIntegrationsSelector: '/:id/ServiceIntegration',
              CompanyIntegrationAppList: '/:id/ServiceIntegration/List',
              CompanyServiceIntegrationAccountSelector:
                '/:id/ServiceIntegration/Detail',
              CompanyIntegrationAgreement: '/:id/ServiceIntegration/Add',
              CompanyServiceIntegrationLink: '/:id/ServiceIntegration/Link',
              CompanyServiceIntegrationAdd: '/:id/ServiceIntegration/Login',
              CompanyServiceIntegrationUnlink: '/:id/ServiceIntegration/Unlink',
              CompanyAppIntegratorDetail: '/AppIntegratorDetails/:pluginKey',
            },
          },
          Settings: {
            path: '/settings',
            screens: {
              SettingsList: '/',
              Languages: '/languages',
              DeleteAccount: '/delete-account',
            },
          },
          Setup: {
            path: '/setup',
            screens: {
              Setup: '/',
              OfflineQueue: '/offline-queue',
            },
          },
          LandscapeSustainability:
            '/crop/:cropId/lot/:lotId/landscape-sustainability',
          CropsCollaborators: '/crop-collaborators-add/:id',
          CropCompany: '/crop/:id/company',
          CropCollaborators: '/crop/:id/collaborators/:status?',
          CollaboratorsDelegateSignatures:
            '/crop/:cropId/collaborators/delegate-signatures/:collaboratorsIds',
          UnlinkCollaborator:
            '/crop/:cropId/collaborators/unlink/:collaboratorsIds',
          CropCollaboratorsAdd: '/crop/:id/collaborators-add',
          ServiceIntegrations: '/crop/:id/services-integrations',
          IntegrationQueue:
            '/crop/:id/services-integrations/queue/:id/:service?',
          SyncDetail: '/crop/:id/services-integrations/detail/:service?',
          CompanyServiceIntegration: '/company/:id/company-integrations',
          CompanyServiceIntegrationAdd:
            '/company/:id/company-integrations-add/:mode?/:service?',
          CompanyServiceIntegrationUnlink:
            '/company/:id/company-integrations-unlink/:service',
          CompanyIntegrationAppList: '/company/:id/company-integrations-list',
          CompanyIntegrationAgreement:
            '/company/:id/company-integrations-agreement/:service',
          CompanyServiceIntegrationLink:
            '/company/:id/company-integration-link/:service',
          CompanyServiceIntegrationsSelector:
            '/company/:id/company-integrations-select',
          CompanyServiceIntegrationAccountSelector:
            '/company/:id/company-integrations-account-select/:service',
          CompanyProfile: '/company-profile',
          CropSelectResponsible: '/crops/create/select-responsible',
          LotsAdd: '/crops/create/lots-add',
          KmzAdd: '/crops/create/lots-add/kmz-add',
          CropCreateConfirm: '/crops/create/confirm',
          LotsSelectorModal: '/selectors/:id/lots/:type?/:status?',
          CollaboratorsSelectorModal: '/selectors/:id/collaborators/:status?',
          SupplySelectorModal: '/selectors/:id/supply/:tag?/:activity?',
          SupplyLoaderScreen:
            '/selectors/:id/supply/:tag?/:activity?/selectedSupply',
          CollaboratorCreate: '/crops/create/collaborators/create',
        },
      },
      Pin: {
        path: 'pin',
        screens: {
          PinWizard: '/wizard',
          EnterPin: '/enter',
        },
      },
    },
  },
}

const RootStack = createStackNavigator()

export default function Navigator() {
  useFirebase()

  const { t } = useContext(LanguageContext)
  const {
    isNotificationModalVisible,
    closeModalNotification,
    notification,
    isFetchingCommonSuccess,
    isDraftsSynchronizing,
    setIsDraftsSynchronizing,
  } = useContext(CommonContext)
  const {
    isAuth,
    config,
    fetchUser,
    user,
    fetchCompany,
    addCompanyConfigUser,
  } = useAuth()
  const dispatch = useDispatch()

  const { isConnected, lowConnection } = useSelector(
    (state) => state.connectionReducer
  )
  const { fetchCropTypes } = useCropTypes()
  const { fetchAgreementTypes } = useTypeAgreements()
  const { fetchActivityTypes } = useActivityTypes()
  const { fetchStorageTypes } = useStorageTypes()

  const [isReadyAuth, setIsReadyAuth] = useState(false)

  const welcomeCompany = useSelector((state) =>
    (state?.auth?.welcomeCompanyList ?? []).find(
      (item) => item?.email === user?.email
    )
  )

  const fetchAuth = async function (token) {
    try {
      await fetchUser()

      if (user && user.companies.length && !config.companySelected) {
        const company = await fetchCompany(user.companies[0].identifier)

        if (company) {
          await addCompanyConfigUser(
            user.config._id,
            company._id,
            company.identifier
          )
          await fetchUser()
        }
      }
      dispatch(setIsAuth(token))
    } catch (err) {
      console.warn('ERR', err)
      if (err?.status === 401) {
        await AsyncStorage.removeItem('USER_TOKEN')
        await AsyncStorage.removeItem('USER_DATA')
        dispatch(setIsAuth(null))
      }
    }
    setIsReadyAuth(true)
  }

  useEffect(() => {
    ;(async () => {
      const token = await AsyncStorage.getItem('USER_TOKEN')
      if (!token) {
        setIsReadyAuth(true)
        return
      }
      await fetchAuth(token)
    })()
  }, [isAuth])

  useEffect(() => {
    if (!isAuth || !user?.config?.languageSelected) {
      return
    }

    fetchCropTypes()
    fetchAgreementTypes()
    fetchActivityTypes()
    fetchStorageTypes()
  }, [isAuth, user?.config?.languageSelected])

  if (!isReadyAuth) {
    return null
  }

  if (!isFetchingCommonSuccess && isAuth) {
    return <CommonLoader />
  }

  return (
    <LayoutWithOutResponsive>
      <NavigationContainer linking={linking}>
        {!isAuth ? (
          <AuthenticationStack />
        ) : !welcomeCompany?.hide && !config?.companySelected ? (
          <CompanyOnboardingStack />
        ) : (
          <RootStack.Navigator>
            <RootStack.Screen
              name='Drawer'
              options={{ headerShown: false }}
              component={DrawerNavigator}
            />

            <RootStack.Screen
              name='SupplySelectorExternal'
              options={{
                title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_9'),
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION.INDEX_PRINCIPAL.TEXT_9')}
                    closeExternal
                  />
                ),
              }}
              component={SupplySelector}
            />

            <RootStack.Screen
              name='ActivityLotsAddExternal'
              options={{
                title: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_14'),
                header: (props) => (
                  <Header
                    {...props}
                    title={t('NAVIGATION.INDEX_PRINCIPAL.TEXT_14')}
                    closeExternal
                  />
                ),
              }}
              component={ActivityLotsAdd}
            />
          </RootStack.Navigator>
        )}
      </NavigationContainer>

      {!isConnected && (
        <View style={styles.contentNotConnection}>
          <Text style={styles.textInfoConnection}>
            {t('NAVIGATION.INDEX_PRINCIPAL.TEXT_10')}
          </Text>
        </View>
      )}

      {lowConnection && (
        <View style={styles.lowConnection}>
          <Text style={styles.textInfoConnection}>
            {t('NAVIGATION.INDEX_PRINCIPAL.TEXT_11')}
          </Text>
        </View>
      )}

      <Snackbar
        visible={isDraftsSynchronizing}
        style={styles.snackbar}
        onDismiss={() => {}}
        action={{
          label: t('NAVIGATION.INDEX_PRINCIPAL.TEXT_12'),
          onPress: () => {
            setIsDraftsSynchronizing(false)
          },
        }}
      >
        <View style={styles.syncSnackbarContainer}>
          <ActivityIndicator
            size='small'
            color={white}
            style={styles.syncSnackbarContainerLoader}
          />

          <Text style={styles.syncSnackbarContainerText}>
            {t('NAVIGATION.INDEX_PRINCIPAL.TEXT_13')}
          </Text>
        </View>
      </Snackbar>

      <Snackbar
        visible={isNotificationModalVisible && !isDraftsSynchronizing}
        style={[styles.snackbar, notification?.extraStyle ?? {}]}
        onDismiss={closeModalNotification}
        duration={notification?.duration ?? 0}
      >
        <Text style={styles.notificationSnackbarContainerText}>
          {notification?.text ?? ''}
        </Text>
      </Snackbar>
    </LayoutWithOutResponsive>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: carbon100,
  },
  contentNavigator: {
    flex: 1,
    width: '100%',
  },
  contentPermanent: {
    width: 960,
    borderLeftColor: carbon300,
    borderLeftWidth: 1,
    borderRightColor: carbon300,
    borderRightWidth: 1,
  },
  snackbar: {
    backgroundColor: graySnackBar,
  },
  syncSnackbarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  syncSnackbarContainerLoader: {
    marginRight: 10,
  },
  syncSnackbarContainerText: {
    color: white,
    fontSize: 14,
    fontWeight: '400',
  },
  notificationSnackbarContainerText: {
    color: white,
    fontSize: 14,
    fontWeight: '400',
  },
  headerOnlyBack: {
    flex: 1,
  },
  lowConnection: {
    padding: 4,
    justifyContent: 'center',
    backgroundColor: Orange500,
  },
  textInfoConnection: {
    textAlign: 'center',
    color: white,

    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  contentNotConnection: {
    justifyContent: 'center',
    backgroundColor: secondary600,
    height: 40,
  },
})
